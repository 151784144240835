<template>
      <canvas ref="chartCanvas" clasds="w-full h-full relative"></canvas>
  <!-- <div class="w-full h-full justify-center items-center relative">

  </div> -->
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, onUpdated, watch } from 'vue';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Tooltip, Legend, TimeScale } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import CRMDoughnutChart from './CRMDoughnutChart.vue';
import CRMHorizontalBarChart from './CRMHorizontalBarChart.vue';

const centerTextPlugin = {
  id: 'centerText',
  afterDraw: function(chart) {
    if (chart.config.type === 'doughnut') {
      const ctx = chart.ctx;
      // const centerX = chart.canvas.width / 2;
      // const centerY = chart.canvas.height / 2;
      const centerX = chart.width / 2;
      const centerY = chart.height / 2;
      // console.log('chart width / height', centerX, centerY)
      const options = chart.config.options.plugins.centerText;
      if (options.display) {
        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = options.fontSize + 'px ' + options.fontFamily;
        ctx.fillStyle = options.color;
        ctx.fillText(options.text, centerX, centerY);
        ctx.restore();
      }
    }
  }
};

const removeGridLinesPlugin = {
  id: 'removeGridLines',
  afterDraw: (chart) => {
    const ctx = chart.ctx;
    const xAxis = chart.scales.x;
    const yAxis = chart.scales.y;
    if(xAxis && yAxis) {
      // console.log(chart)
      // console.log(chart.ctx)
      // console.log('xAxis', xAxis)
      // console.log('yAxis', yAxis)
      const topY =  yAxis._gridLineItems[22]
      const bottomY = yAxis._gridLineItems[23]
      xAxis._gridLineItems.forEach((gridLine) => {
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(gridLine.x1 - 1, topY.y1 + 0.5);
        ctx.lineTo(gridLine.x1 - 1, bottomY.y1 - 0.5);
        ctx.strokeStyle = chart.config._config.style.color; // Set to background color
        ctx.lineWidth = 3;
        ctx.stroke();
        ctx.restore();
    });
  }
    }

};


ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, TimeScale, Tooltip, Legend, ChartDataLabels)

const props = defineProps([
    'type',
    'options',
    'data',
    'style',
    'printDimensions'
])

const emit = defineEmits([
  'chart-click'
])


const chartCanvas = ref(null);
let chartInstance = null;

onMounted(() => {
  console.log('CRMChart: onMounted fired')
    if (chartCanvas.value) {
      if(props.type === 'doughnut') {
        ChartJS.register(centerTextPlugin)
      } else if (props.type === 'bar') {
        ChartJS.register(removeGridLinesPlugin)
      }
    chartInstance = new ChartJS(chartCanvas.value, {
      type: props.type, // Change to 'bar', 'pie', etc. as needed
      data: props.data,
      options: {
        ...props.options,
        onClick: (event, elements) => {
          if (elements.length > 0) {
            const datasetIndex = elements[0].datasetIndex;
            const dataIndex = elements[0].index;
            const label = chartInstance.data.labels[dataIndex];
            const value = chartInstance.data.datasets[datasetIndex].data[dataIndex];
            // console.log('Clicked:', label, value);
            emit('chart-click', chartInstance.options.id, datasetIndex, dataIndex, value)
          }
        },
        // onmouseenter: (event, elements) => {
        //   if (elements.length > 0) {
        //     const datasetIndex = elements[0].datasetIndex;
        //     const dataIndex = elements[0].index;
        //     const label = chartInstance.data.labels[dataIndex];
        //     const value = chartInstance.data.datasets[datasetIndex].data[dataIndex];

        //     console.log('hovered:', label, value);
        //   }
        // },
      },
      style: props.style,
      plugins: [ChartDataLabels]
    });
    window.addEventListener('beforeprint', (event) => {
      // console.log('before print')
      let printDimensions = []
      if(props.printDimensions) {
        printDimensions = props.printDimensions
      }
      chartInstance.resize(...printDimensions);
      // chartInstance.resize();
    })
    window.addEventListener('afterprint', () => {
      // console.log('after print')
      chartInstance.resize();
  });
  }
})

onUpdated(() => {
  console.log('CRMChart: onUpdated fired')
  if (chartInstance) {
    chartInstance.data = props.data;
    chartInstance.options = {
        ...props.options,
        onClick: (event, elements) => {
          if (elements.length > 0) {
            const datasetIndex = elements[0].datasetIndex;
            const dataIndex = elements[0].index;
            const label = chartInstance.data.labels[dataIndex];
            const value = chartInstance.data.datasets[datasetIndex].data[dataIndex];
            // console.log('Clicked:', label, value);
            emit('chart-click', chartInstance.options.id, datasetIndex, dataIndex, value)
          }
        },
        // onmouseenter: (event, elements) => {
        //   if (elements.length > 0) {
        //     const datasetIndex = elements[0].datasetIndex;
        //     const dataIndex = elements[0].index;
        //     const label = chartInstance.data.labels[dataIndex];
        //     const value = chartInstance.data.datasets[datasetIndex].data[dataIndex];

        //     console.log('hovered:', label, value);
        //   }
        // },
      }
    chartInstance.resize();
    chartInstance.update();
  } else {
    console.log('no chartInstance Value')
  }
})


</script>

