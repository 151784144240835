<template>
    <tr class="odd:bg-gray-50 even:bg-white cursor-pointer hover:bg-[#EEF2FF] text-sm" @click="toggleViewCompanyActions">
        <td
            class="px-2 py-1 border-r border-b border-gray-200 max-w-[280px] align-top text-left bg-inherit" 
            v-for="(field, index) in orderedCompanyFields" 
            :key="index"
            :style="getCellStyleByIndex(index)"
            >
            <div class="truncate flex justify-start items-start">
                {{ handleFieldDisplay(field) }}
            </div>
        </td>
    </tr>
    <tr v-show="visibleCompanyActions" class="even:bg-gray-50 odd:bg-white text-xs">
        <td colspan="4" class="sticky z-10 left-0 bg-inherit w-full px-2 pt-1.5 pb-6 border border-r-2   border-gray-300">
            <div class="w-full flex justify-start pb-1.5 pt-0.5 border-b font-semibold tracking-wider border-gray-200">
                <div class="w-[20%]  px-2">Show on Report:</div>
                <div class="w-[50%]  px-2">Event Type:</div>
                <div class="w-[30%]  px-2">Resolved Date:</div>
            </div>
            <div v-for="crmAction in companyActions" :key="crmAction.id" class="py-1 border-b border-gray-200 last:border-none cursor-pointer hover:bg-[#EEF2FF]" :style="getCRMActionStyleByAction(crmAction)">
                <div class="w-full flex justify-start">
                    <div class="w-[20%] px-2"><input type="checkbox" id="checkbox" :checked="crmAction.show_on_crm_report" @change="toggleSelected"/></div>
                    <div class="w-[50%] px-2">{{ crmAction.action.action_name }}</div>
                    <div class="w-[30%] px-2">{{ formatDate(crmAction.resolved_date)  }}</div>
                </div>
            </div>
        </td>
    </tr>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue';
import { formatDate } from '@/composables/formatDate';

const props = defineProps(['company', 'headers'])
const emit = defineEmits([])

const visibleCompanyActions = ref(false)

const orderedCompanyFields = computed(() => {
    let orderedFields = []
    props.headers.forEach(header => {
        orderedFields.push({fieldName: header.fieldName, fieldValue: props.company[header.fieldName]})
    });
    return orderedFields
})

const companyActions = computed(() => {
    if(props.company) {
        return props.company.crm_actions
    }
    return []
})


function toggleViewCompanyActions() {
    visibleCompanyActions.value = !visibleCompanyActions.value
}

function handleFieldDisplay(field) {
    if(field.fieldValue) {
        if(field.fieldName == 'campaign') {
            return field.fieldValue.campaign_name
        }
        else if(field.fieldName == 'tier_object') {
            return field.fieldValue.tier_name
        }
        else if(field.fieldName == 'crm_actions') {
            if(field.fieldValue.length == 1) {
                return `${field.fieldValue.length} CRM Action`
            }
            return `${field.fieldValue.length} CRM Actions`
        }   
        else if(field.fieldName == 'furthest_crm_action') {
            return `${formatDate(field.fieldValue.resolved_date)}: ${field.fieldValue.action.action_name}`
        }
        else if (field.fieldName == 'computed_crm_status') {
            return field.fieldValue.label
        }
        return field.fieldValue
    }
    return ''
}

function getCellStyleByIndex(index) {
    if(index == 0) {
     return `position: sticky; min-width: 75px; left: 0px; z-index: 10;`
    } else if(index == 1) {
        return `position: sticky; min-width: 300px; left: 75px; z-index: 10;`
    } else if (index == 2) {
      return `position: sticky; min-width: 280px; left: 375px; z-index: 10;`
    } else if (index == 3) {
        return `position: sticky; min-width: 155px; left: 655px; z-index: 10; border-right: 2px solid #D1D5DB;` //#D1D5DB #A3A3A3
    } else {
        return 'z-index: 5'
    }
}

function getCRMActionStyleByAction(crmAction) {
    if(crmAction && props.company.furthest_crm_action) {
        if(crmAction.id == props.company.furthest_crm_action.id) {
            return 'background-color: #C7D2FE;'
        } 
    }
    return ''
}

</script>