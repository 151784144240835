<template>
    <div class="w-full min-h-[100vh]">
        <div class="w-full h-10 bg-neutral-50 flex items-center justify-start shadow-md rounded m-1 p-1 space-x-4">
            <div class="w-fit">
                <PaginationLoader
                    class="rounded-full hover:bg-gray-300"
                    @refetch-results="loadCompanies"
                    :isLoading="isLoading"
                  ></PaginationLoader>
            </div>
            <div v-if="viewMode === 'cards'" class="w-fit">
                <ListSVG @click="setViewMode('list')" class="hover:bg-gray-300 rounded hover:cursor-pointer"></ListSVG>
            </div>
            <div v-if="viewMode === 'list'" class="w-fit">
                <SquaresSVG @click="setViewMode('cards')" class="hover:bg-gray-300 rounded hover:cursor-pointer"></SquaresSVG>
            </div>
        </div>
        <div v-if="viewMode === 'cards'" class="p-4 w-full h-full">
            <div v-if="clients" class="max-w-full grid grid-cols-3 gap-3 overflow-x-auto">
                <div v-for="(clientObject, client) in clients" :key="client" class="h-full flex w-full justify-center px-1">
                    <div class="flex flex-col items-center w-full">
                        <div class="w-full flex justify-center bg-dark-primary text-white rounded-md mb-1">
                            <div class="">
                                {{ client }}
                            </div>
                        </div>
                        <div class="flex flex-col items-center max-h-96 overflow-auto w-full">
                            <div v-for="company in sortedCompanies(clientObject.companies)" :key="company.id" class="flex w-full items-start px-1">
                                <HighPriorityCompanyRow :view-mode="viewMode" :company="company" :campaigns="campaigns" :crm-action-types="crmActionTypes" :event-users="eventUsers" :crm-statuses="crmStatuses" @refetch-companies="loadCompanies"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="viewMode === 'list'" class="p-4">
            <div class="flex flex-col items-center max-h-1/2 overflow-auto space-y-1 w-full">
                <div v-for="company in listModeCompanies" :key="company.id" class="flex w-full items-start px-1">
                    <HighPriorityCompanyRow :view-mode="viewMode" :company="company" :campaigns="campaigns" :crm-action-types="crmActionTypes" :event-users="eventUsers" :crm-statuses="crmStatuses" @refetch-companies="loadCompanies"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { getHighPriorityCompanies } from '@/services/Company';
import {ref, computed} from 'vue'
import { useStore } from 'vuex';
import HighPriorityCompanyRow from './HighPriorityCompanyRow.vue';
import PaginationLoader from "../base-components/PaginationLoader.vue";
import ListSVG from '../svg-components/ListSVG.vue';
import SquaresSVG from '../svg-components/SquaresSVG.vue';
import { getActions } from '@/services/CrmAction';
import { getCampaigns } from '@/services/Campaign';
import { getCrmStatuses } from '@/services/CrmStatus';
import { getUsersByGroup } from '@/services/User';

const store = useStore()
const clients = ref(null)
const campaigns= ref([])
const crmStatuses = ref([])
const crmActionTypes = ref([])
const eventUsers = ref([])
const isLoading = ref(false)
const viewMode = ref('cards')

function scroll(){
    console.log('scrollin now')
}

async function loadCompanies(){
    const token = store.state.auth.token
    isLoading.value = true
    const result = await getHighPriorityCompanies(token)
    if(result.status == 200){
        clients.value = result.data.clients
        isLoading.value = false
    }
}

function setViewMode(view) {
    viewMode.value = view
}




function sortedCompanies(companies) {
    let darkGreen = []
    let lightGreen = []
    let yellow = []
    let noColor = []
    companies.forEach((company) => {
        if(company.current_event){
            if(company.current_event.highlight_color === 'yellow'){
                yellow.push(company)
            }
            else if(company.current_event.highlight_color === 'dark_green'){
                darkGreen.push(company)
            }
            else if(company.current_event.highlight_color === 'light_green'){
                lightGreen.push(company)
            } else {
                noColor.push(company)
            }
        } else {
                noColor.push(company)
        }
    })
    return yellow.concat(lightGreen, darkGreen, noColor)
}

const listModeCompanies = computed(() => {
    let allCompanies = []
    if(clients.value){
        const clientValues = Object.values(clients.value)
        clientValues.forEach((object) => {
            allCompanies = allCompanies.concat(object.companies)
        })
    }
    return sortedCompanies(allCompanies)
})



async function loadActions() {
    const token = store.state.auth.token
    const response = await getActions(token)
    // console.log('response', response.data)
    crmActionTypes.value = response.data
}
  
async function loadCampaigns(){
    const token = store.state.auth.token
    const result = await getCampaigns(token)
    campaigns.value = result
  }

    
async function loadCrmStatuses(){
    const token = store.state.auth.token
    const result = await getCrmStatuses(token)
    crmStatuses.value = result
}

async function loadCrmUsers(){
    let payload = {
        token: store.state.auth.token,
        endpoint: 'list_users',
        group: 'account_manager_group'
    }
    const result = await getUsersByGroup(payload)
    eventUsers.value = result.users
}

loadCompanies()
loadActions()
loadCampaigns()
loadCrmStatuses()
loadCrmUsers()

</script>