<template>
    <div class="max-w-xl rounded-md pt-1">
        <div class="grid grid-cols-5 gap-x-2">
            <div v-if="event" class="rounded-t-md bg-gray-300 p-1">
                <div class="">
                    Priority Color:
                </div>
            </div>
            <div :class="{'col-span-5' : !event, 'col-span-4' : event}" class="rounded-t-md bg-gray-300 p-1">
                <div class="">
                    New Event:
                </div>
            </div>
            <div v-if="event" class="flex flex-col py-1">
                <div class="flex justify-between">
                    <div @click="setHighlightColor('yellow')" class="hover:cursor-pointer w-8 h-6 rounded bg-yellow-400 border border-gray-300">

                    </div>
                    <div @click="setHighlightColor('light_green')" class="hover:cursor-pointer w-8 h-6 rounded bg-green-300 border border-gray-300">

                    </div>
                    <div @click="setHighlightColor('dark_green')" class="hover:cursor-pointer w-8 h-6 rounded bg-green-600 border border-gray-300">

                    </div>
                    <div @click="setHighlightColor('none')" class="hover:cursor-pointer w-8 h-6 rounded  border border-gray-300 flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-4 w-4">
                            <path fill-rule="evenodd" d="m6.72 5.66 11.62 11.62A8.25 8.25 0 0 0 6.72 5.66Zm10.56 12.68L5.66 6.72a8.25 8.25 0 0 0 11.62 11.62ZM5.105 5.106c3.807-3.808 9.98-3.808 13.788 0 3.808 3.807 3.808 9.98 0 13.788-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788Z" clip-rule="evenodd" />
                        </svg>

                    </div>
                </div>
            </div>
            <div :class="{'col-span-5' : !event, 'col-span-4' : event}" class="flex flex-col">
                <form @submit.prevent="createNewAction" action="">
                    <div class="flex">
                        <select required v-model="newActionData.action_id" class="bg-gray-50 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" name="" id="">
                            <option value="">---Event Type---</option>
                            <option v-for="event in eventTypes" :key=event.id :value="event.id">{{ event.action_name }}</option>
                        </select>
                        <input required v-model="newActionData.resolved_date" id="crmNewActionDueDate" type="date"
                            class="bg-gray-50 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
    
                        <input v-model="newActionData.action_notes" id="crmNewActionDueDate" type="text" placeholder="Event Summary Note"
                            class="bg-gray-50 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
    
                        <select v-model="newActionData.user_id" id="crmNewActionUser"
                            class="bg-gray-50 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                            <option value="">---User---</option>
                            <option value=3>Scott Shedd</option>
                            <option v-for="crmUser in eventUsers" :key="crmUser.id" :value="crmUser.id">{{ crmUser.first_name}} {{ crmUser.last_name }}</option>
                        </select>
                        <div class="p-1 flex justify-center items-center bg-accent-primary text-white">
                            <button>
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- <div class="flex space-x-2">
            <div class="flex flex-col">
                <div class="underline">
                    Priority Color:
                </div>
                <div class="flex justify-between">
                    <div class="hover:cursor-pointer w-5 h-5 rounded bg-yellow-400 border border-gray-300">

                    </div>
                    <div class="hover:cursor-pointer w-5 h-5 rounded bg-green-300 border border-gray-300">

                    </div>
                    <div class="hover:cursor-pointer w-5 h-5 rounded bg-green-600 border border-gray-300">

                    </div>
                </div>
            </div>
            <div class="flex flex-col">
                <div class="underline">
                    New Event:
                </div>
                <div class="grid grid-cols-4">
                    <select class="bg-gray-50 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" name="" id="">
                        <option :value="null">---Event Type---</option>
                    </select>
                    <input id="crmNewActionDueDate" type="date"
                        class="bg-gray-50 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >

                    <input id="crmNewActionDueDate" type="text" placeholder="Event Summary Note"
                        class="bg-gray-50 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >

                    <select id="crmNewActionUser"
                        class="bg-gray-50 p-1 border w-full border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                        <option :value="null">---User---</option>
                        <option value=3>Scott Shedd</option>
                    </select>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script setup>
import { defineProps, ref, defineEmits, reactive } from 'vue';
import { markHighlightColor } from '@/services/CrmAction';
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';
import { createAction } from '@/services/CrmAction';

const store = useStore()
const $toast = useToast()
const props = defineProps(['company', 'event', 'eventTypes', 'eventUsers'])

const colorOptions = ref([
    'yellow',
    'light_green',
    'dark_green'
])

const emit = defineEmits(['refetch-companies'])

const priorityColor = ref(null)

async function setHighlightColor(color){
    const token = store.state.auth.token
    const result = await markHighlightColor(props.event.id, color, token)
    if(result.status == 200){
        emit('refetch-companies')
    } else {
        $toast.open({
            message: 'Error, could not update CRM Event',
            type: 'error',
            duration: 3000
        })
    }
}

const newActionData = reactive({
    action_id: null,
    resolved_date: null,
    action_notes: null,
    status: "Done",
    user_id: null,
    company_id: props.company.id,
    campaign_id: props.company.campaign.id
})

async function createNewAction(){
    const token = store.state.auth.token
    const result = await createAction(newActionData, token)
    if(result.status == 201){
        emit('refetch-companies')
    } else {
        $toast.open({
            message: 'Error, could not create new CRM Event',
            type: 'error',
            duration: 3000
        })
    }
}

function addNewEvent(){
    console.log(newActionData)
}


</script>