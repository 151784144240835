<template>
    <div @click.right.prevent="showEditPopover" @scroll.passive="hidePopover, closeEditPopover" ref="highPriorityCompanyRow" class="w-full">
        <div v-if="currentEvent && viewMode === 'cards'" @mouseover="showPopover" :class="colorStyling" @mouseleave="hidePopover" class="flex items-center justify-between w-full text-sm p-1 border-b hover:cursor-pointer" @click.exact="showDetails">
            <div class="w-full">
                {{ company.company_name }} [{{ `${currentEvent.action.action_name}, ${formatDateTwoDigitYear(currentEvent.resolved_date)}` }}]
            </div>
        </div>
        <div v-else-if="currentEvent && viewMode === 'list'" @mouseover="showPopover" @mouseleave="hidePopover" class="flex items-center bg-neutral-50 justify-between w-full text-sm p-1 border-b hover:cursor-pointer" @click.exact="showDetails">
            <div :class="colorStyling" class="h-5 w-5 border border-neutral-50 mr-1 rounded">

            </div>
            <div class="w-full">
                {{ company.company_name }} [{{ `${currentEvent.action.action_name} - ${currentEvent.action_notes}, ${formatDateTwoDigitYear(currentEvent.resolved_date)}` }}]
            </div>
            <span class="border bg-gray-500 border-gray-500 font-semibold text-white whitespace-nowrap rounded-l-full rounded-r-full h-fit w-fit px-1.5 mr-0.5 text-center text-xs">
                {{ company.campaign.client.client_name }}
            </span>
        </div>
        <div v-else-if="viewMode === 'list'" class="flex items-center justify-between w-full text-sm p-1 border-b hover:cursor-pointer bg-neutral-50" @click.exact="showDetails">
            <div :class="colorStyling" class="h-5 w-5 border border-neutral-50 mr-1 rounded">

            </div>
            <div class="w-full">
                {{ company.company_name }} []
            </div>
            <span class="border bg-gray-500 border-gray-500 font-semibold text-white whitespace-nowrap rounded-l-full rounded-r-full h-fit w-fit px-1.5 mr-0.5 text-center text-xs">
                {{ company.campaign.client.client_name }}
            </span>
        </div>
        <div v-else class="flex items-center justify-between w-full text-sm p-1 border-b hover:cursor-pointer bg-neutral-50" @click.exact="showDetails">
            <div class="w-full">
                {{ company.company_name }} []
            </div>
        </div>
        <base-popover-click @close-popover="closeEditPopover" v-if="editPopoverVisible" :xPosition="xPosition" :yPosition="yPosition">
            <!-- <template #body> -->
                <EditPopover :company="company" :event="currentEvent" :event-users="eventUsers" :event-types="crmActionTypes" @refetch-companies="fetchNewCompanies"/>
            <!-- </template> -->
        </base-popover-click>
        <base-popover-hover-dark
            class="max-w-fit"
            v-if="isPopoverVisible"
            :xPosition="xPosition"
            :yPosition="yPosition"
        >
            <template #title>
            <span class="text-sm">Event Note:</span>
            </template>
            <template #body>
                <div class="p-1 border-gray-300 bg-neutral-50 w-fit">
                    {{ currentEvent.action_notes }}
                </div>
            </template>
        </base-popover-hover-dark>
        <base-modal :is-form-visible="crmDetailsVisible" @close="closeDetails">
            <template #header>
            <h2 class="text-lg">
                {{ company.company_name }} CRM Details
            </h2>
            </template>
            <template #body>
            <CRMDetailsPage :company="company" :campaigns="campaigns" :crm-statuses="crmStatuses" :crm-action-types="crmActionTypes" @refetch-companies="fetchNewCompanies" />
            </template>
        </base-modal>
    </div>
</template>

<script setup>
import { defineProps, computed, ref, defineEmits, watch } from 'vue';
import { formatDateTwoDigitYear } from '@/composables/formatDate';
import CRMDetailsPage from '../CRMDetailsPage.vue';
import BasePopoverHoverDark from '../BasePopoverHoverDark.vue'
import EditPopover from './EditPopover.vue';
import { useClickOutside, useRightClickOutside } from '@/composables/useClickOutside';
import { markHighlightColor } from '@/services/CrmAction';
import { useStore } from 'vuex';
import { useToast } from 'vue-toast-notification';

const $toast = useToast()
const props = defineProps([
    'company',
    'campaigns',
    'crmStatuses',
    'crmActionTypes',
    'eventUsers',
    'viewMode'
])

const store = useStore()
const emit = defineEmits(['refetch-companies'])

const isPopoverVisible = ref(false)
const isMouseover = ref(false)
const editPopoverVisible = ref(false)
const highPriorityCompanyRow = ref(null)
const xPosition = ref(0)
const yPosition = ref(0)


useClickOutside(highPriorityCompanyRow, () => {
  closeEditPopover()
})

useRightClickOutside(highPriorityCompanyRow, () => {
  closeEditPopover()
})

watch(editPopoverVisible, () => {
    console.log('edit popover visible', editPopoverVisible.value)
})

async function setHighlightColor(color){
    if(currentEvent.value){
        const token = store.state.auth.token
        const result = await markHighlightColor(currentEvent.value.id, color, token)
        if(result.status == 200){
            emit('refetch-companies')
        } else {
            $toast.open({
                message: 'Error, could not update CRM Event',
                type: 'error',
                duration: 3000
            })
        }
    }
}


const colorStyling = computed(() => {
    let style = []
    if(currentEvent.value){
        if(currentEvent.value.highlight_color === 'yellow'){
            style.push('bg-yellow-400')
        }
        else if(currentEvent.value.highlight_color === 'light_green'){
            style.push('bg-green-200')
        }
        else if(currentEvent.value.highlight_color === 'dark_green'){
            style.push('bg-green-600')
        } else {
            style.push('bg-neutral-50')
        }
    } else {
        style.push('bg-neutral-50')
    }
    return style.join(' ')
})

const ctrlPressed = ref(false)

function registerControlPressed(e) {
    e.preventDefault()
    if(e.key === 'Control') {
        ctrlPressed.value = true
    }
}

function registerControlReleased(e) {
    e.preventDefault()
    if(e.key === 'Control') {
        ctrlPressed.value = false
    }
}

function registerKeyPress(e) {
    if(ctrlPressed.value){
        if(e.key === '1'){
            setHighlightColor('yellow')
        }
        else if (e.key === '2') {
            setHighlightColor('light_green')
        }
        else if (e.key === '3') {
            setHighlightColor('dark_green')
        } else if (e.key === '0') {
            setHighlightColor('none')
        }
    }
}

function handleScroll(){
    console.log('scrolling')
    closeEditPopover()
}

function showPopover() {
    if(!editPopoverVisible.value && props.viewMode == 'cards'){
        isPopoverVisible.value = true;
        xPosition.value = highPriorityCompanyRow.value.getBoundingClientRect().left
        yPosition.value = highPriorityCompanyRow.value.getBoundingClientRect().bottom
    }
    isMouseover.value = true
    document.addEventListener("keydown", registerControlPressed, true)
    document.addEventListener("keyup", registerControlReleased, true)
    document.addEventListener("keydown", registerKeyPress, true)
}

function showEditPopover() {
    hidePopover()
    editPopoverVisible.value = true;
    const rowBottom = highPriorityCompanyRow.value.getBoundingClientRect().bottom
    const windowBottom = window.innerHeight
    if(windowBottom - rowBottom <= 100){
        xPosition.value = highPriorityCompanyRow.value.getBoundingClientRect().left + 30
        yPosition.value = highPriorityCompanyRow.value.getBoundingClientRect().bottom - 100
        if(props.viewMode == 'list'){
            xPosition.value += 10
        }
    } else {
        xPosition.value = highPriorityCompanyRow.value.getBoundingClientRect().left + 30
        yPosition.value = highPriorityCompanyRow.value.getBoundingClientRect().bottom
        if(props.viewMode == 'list'){
            xPosition.value += 10
        }
    }
    document.addEventListener("wheel", handleScroll, true)
}

function closeEditPopover() {
    editPopoverVisible.value = false;
    xPosition.value = 0
    yPosition.value = 0
    document.removeEventListener("wheel", handleScroll, true)
}

function hidePopover() {
    if(isPopoverVisible.value){
        isPopoverVisible.value = false;
        xPosition.value = 0;
        yPosition.value = 0;
    }
    isMouseover.value = false  
    document.removeEventListener("keydown", registerControlPressed, true)
    document.removeEventListener("keyup", registerControlReleased, true)
    document.removeEventListener("keypress", registerKeyPress, true)
}

const crmDetailsVisible = ref(false)

function closeDetails(){
    crmDetailsVisible.value = false
}

function showDetails(){
    crmDetailsVisible.value = true
}


const events = computed(() => {
    if(props.company.crm_actions.length > 0){
        return props.company.crm_actions.filter(action => action.campaign.id === props.company.campaign.id).sort(function(a,b) {
            return new Date(b.resolved_date) - new Date(a.resolved_date)
        }).map((crm_action) => {
            return `${crm_action.action.action_name}, ${formatDateTwoDigitYear(crm_action.resolved_date)}`
        })
    } else {
        return null
    }
})

const mostRecentEvent = computed(()=> {
    if(events.value){
        return events.value[0]
    } else {
        return null
    }
})

const currentEvent = computed(() => {
    if(props.company.current_event){
        return props.company.current_event
    } else {
        return null
    }
})

function fetchNewCompanies(){
    emit('refetch-companies')
}

</script>

