<template>
    <div v-if="printView" class="w-full h-full flex flex-col ">
        <div class="w-full text-gray-700 tracking-widest font-semibold text-lg p-4 space-x-2 flex justify-start items-center">
          <div> Target List:</div>
          <div class="text-base items-center justify-center tracking-wider whitespace-nowrap text-gray-600 pt-0.5">
                {{ numCompanies }} {{ companyLabel }} in {{ numCampaigns }} {{ campaignLabel }}
            </div>
        </div>
        <div class="w-full h-[calc(100vh-12rem)] flex -x-scroll border-l border-gray-200 border-t">
            <table class="w-full h-full ">
                <thead class="-top-[1px] left-0 right-0">
                    <th v-for="(header, index) in printedHeaders" :key="header.fieldName" class="px-2 py-1 border-r border-b whitespace-nowrap border-gray-200 bg-white text-gray-700 tracking-widest font-semibold text-sm sticky -top-[1px]">
                        <div v-if="index==0" class="uppercase flex justify-start">
                            {{ formatHeader(header.fieldName) }}
                        </div>
                        <div v-else class="flex justify-start">
                            {{ formatHeader(header.fieldName) }}
                        </div>
                    </th>
                </thead>
                <tbody class="-y-auto">
                    <tr v-for="company in selectedCompanies" :key="company.id" class="odd:bg-gray-50 even:bg-white h-8">
                        <td  v-for="[fieldName, fieldValue] in printedFields(Object.entries(company))" :key="fieldName" class="px-2 py-1 border-r border-b border-gray-200 max-w-[450px] align-top text-left">
                            <div class="truncate flex justify-start items-start">
                                {{ handleFieldDisplay(fieldName, fieldValue) }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-else class="w-full h-full flex flex-col bg-white px-4 border-x border-gray-300">
        <div class="w-full text-gray-700 tracking-widest font-semibold text-lg py-4 space-x-2 flex justify-start items-center">
          <div> Target List:</div>
          <div class="text-base items-center justify-center tracking-wider whitespace-nowrap text-gray-600 pt-0.5">
                {{ numCompanies }} {{ companyLabel }} in {{ numCampaigns }} {{ campaignLabel }}
            </div>
        </div>
        <div class="w-full h-[calc(100vh-12rem)] flex overflow-x-scroll border-l border-gray-200 border">
            <table class="w-full h-full border-separate border-spacing-0 bg-gray-50">
                <thead class="-top-[1px] left-0 right-0 relative">
                    <th 
                    v-for="(header, index) in columnHeaders" 
                    :key="header.fieldName" 
                    class="px-2 py-1 border-r border-b whitespace-nowrap border-gray-200 bg-white text-gray-700 tracking-widest font-semibold text-sm sticky -top-[1px]"
                    :style="getHeaderStyleByIndex(index)"
                    >
                        <div v-if="index==0" class="uppercase flex justify-start">
                            {{ formatHeader(header.fieldName) }}
                        </div>
                        <div v-else class="flex justify-start">
                            {{ formatHeader(header.fieldName) }}
                        </div>
                    </th>
                </thead>
                <tbody class="overflow-y-auto -top-[1px] relative">
                    <CRMTargetListRow
                        v-for="company in selectedCompanies" :key="company.id"
                        :company="company"
                        :headers="columnHeaders"
                    />  
                    <!-- <tr v-for="company in selectedCompanies" :key="company.id" class="odd:bg-gray-50 even:bg-white">
                        <td  v-for="[fieldName, fieldValue] in Object.entries(company)" :key="fieldName" class="px-2 py-1 border-r border-b border-gray-200 max-w-[450px] align-top text-left">
                            <div class="truncate flex justify-start items-start">
                                {{ handleFieldDisplay(fieldName, fieldValue) }}
                            </div>
                        </td>
                    </tr> -->
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, watch, computed, onMounted } from 'vue';
import CRMTargetListRow from './CRMTargetListRow.vue';
import { formatDate } from '@/composables/formatDate';

const props = defineProps([
    'printView',
    'clientName',
    'campaigns',
    'campaignOptions',
    'selectedCompanies',
])

const columnHeaders = ref([])

const displayedCompanies = ref(props.selectedCompanies ? props.selectedCompanies : [])

onMounted(() => {
    if(props.selectedCompanies && props.selectedCompanies.length > 0) {
        const companyObject = props.selectedCompanies[0]
        columnHeaders.value = Object.keys(companyObject).map((companyField, index) => {
            if(companyField == 'furthest_crm_action') {
                return {fieldName: companyField, display: true, ordinal: 1.5}
            } else if (companyField == 'computed_crm_status') {
                return {fieldName: companyField, display: true, ordinal: 1.7}
            } else {
                return {fieldName: companyField, display: true, ordinal: index}
            }
        }).sort((a, b) => {return a.ordinal - b.ordinal})
    }
})

const printedHeaders = computed(() => {
    if(columnHeaders.value && columnHeaders.value.length > 0) {
        return [
            columnHeaders.value[1],
            columnHeaders.value[21],
            columnHeaders.value[3],
            columnHeaders.value[14],
            columnHeaders.value[15],
        ]
    }
    return []
})

function printedFields(companyFieldList) {
    console.log('printedFields')
    console.log(companyFieldList)
    return [
        companyFieldList[1],
        companyFieldList[21],
        companyFieldList[3],
        companyFieldList[14],
        companyFieldList[15],
    ]
}

const numCompanies = computed(() => {
    return props.selectedCompanies ? props.selectedCompanies.length : 0
})

const companyLabel = computed(() => {
    if(numCompanies.value == 1) {
        return 'Target'
    }
    return 'Targets'
})

const numCampaigns = computed(() => {
    return props.campaigns ? props.campaigns.length : 0
})

const campaignLabel = computed(() => {
    if(numCampaigns.value == 1) {
        return 'Campaign'
    }
    return 'Campaigns'
})

function handleFieldDisplay(fieldName, fieldValue) {
    if(fieldValue) {
        if(fieldName == 'campaign') {
            return fieldValue.campaign_name
        }
        else if(fieldName == 'tier_object') {
            return fieldValue.tier_name
        }
        else if(fieldName == 'crm_actions') {
            if(fieldValue.length == 1) {
                return `${fieldValue.length} CRM Action`
            }
            return `${fieldValue.length} CRM Actions`
        }   
        else if(fieldName == 'furthest_crm_action') {
            return `${formatDate(fieldValue.resolved_date)}: ${fieldValue.action.action_name}`
        }
        else if (fieldName == 'computed_crm_status') {
            return fieldValue.label
        }
        return fieldValue
    }
    return ''
}

function getHeaderStyleByIndex(index) {
    if(index == 0) {
     return `position: sticky; min-width: 75px; left: 0px; z-index: 20;`
    } else if(index == 1) {
        return `position: sticky; min-width: 300px; left: 75px; z-index: 20;`
    } else if (index == 2) {
      return `position: sticky; min-width: 280px; left: 375px; z-index: 20;`
    } else if (index == 3) {
        return `position: sticky; min-width: 155px; left: 655px; z-index: 20; border-right: 2px solid #D1D5DB;` //#D1D5DB #A3A3A3
    } else {
        return 'z-index: 10'
    }
}

function formatHeader(fieldName) {
    let headerLabel = fieldName.replaceAll('_', ' ').replace(/(^| )(\w)/g, function(x) {return x.toUpperCase();}).replaceAll('Url', 'URL').replaceAll('Crm', 'CRM')
    return headerLabel
}

</script>