import { createRouter } from "vue-router";
import { createWebHistory } from "vue-router";

import GuestWelcome from "./components/GuestWelcome.vue";
// import Companies from './components/Companies.vue';
// import CompaniesWithCampaigns from './components/CompaniesWithCampaigns.vue'
import CampaignActivitiesSummary from "./components/CampaignActivitiesSummary.vue";
// import CompaniesForClientReview from './components/CompaniesForClientReview.vue'
import CampaignCrmStatusSummary from './components/CampaignCrmStatusSummary.vue'
import ConfigurableCompanies from './components/ConfigurableCompanies.vue'
import CreatePageConfig from './components/CreatePageConfig.vue'
import ManagePageConfig from './components/ManagePageConfig.vue'
import NewCrm from './components/NewCrm.vue'
import PDFTierView from './components/PDFTierView.vue'
import DLACompanies from '././components/DLACompanies.vue';
import Clients from './components/Clients.vue';
import PageNotFound from './components/PageNotFound.vue';
import UserAuth from './components/UserAuth.vue';
import store from './store/index.js';
import AddMultipleCompanies from './components/AddMultipleCompanies.vue'
import Contacts from './components/contact/Contacts.vue';
import ReferrersPage from './components/ReferrersPage.vue';
import ProjectAssignmentPage from './components/project-assignments/ProjectAssignmentPage.vue'
import ResearchGuide from './components/ResearchGuide.vue';
import TrainingDocuments from './components/training-document-components/TrainingDocuments.vue';
import ResearchProjects from './components/research-projects/ResearchProjects.vue'
import EmailsReceived from './components/emails-received/EmailsReceived.vue'
import SuggestedCompaniesForEmails from './components/emails-received/SuggestedCompaniesForEmails.vue'
import TimecardMainPage from './components/timecards/TimecardMainPage.vue';
import Campaigns from './components/campaign-components/Campaigns.vue';
import PartsManufacturerApprovals from './components/PartsManufacturerApprovals'
import BusinessTypesSectorsSubSectors from './components/businesstypes+sectors+subsectors-components/BusinessTypes+Sectors+SubSectors.vue';
import EmailTemplates from './components/email-templates/EmailTemplates.vue';
import CreateEmailTemplate from './components/email-templates/CreateNewTemplate.vue';
import EditEmailTemplate from "./components/email-templates/EditEmailTemplate.vue";
import JobQueuePage from './components/job-queue/JobQueuePage.vue';
import MaintenancePage from './components/MaintenancePage.vue';
import ClientAppendices from './components/client_appendices/ClientAppendices.vue';
import CRMForPDF from './components/crm-for-pdf-components/CRMForPDF.vue'
import InstructionalVideos from './components/InstructionalVideos.vue'
import AllTasks from './components/AllTasks.vue'
import UploadCompanies from './components/UploadCompanies.vue'
import Admin from './components/Admin.vue'
import AdminImportTools from './components/admin_import_tools/AdminImportTools.vue'
import CampaignChangeReport from './components/CampaignChangeReport.vue'
import Invoices from './components/invoice_components/Invoices.vue'
import InvoicesThatNeedReminding from './components/invoice_components/InvoicesThatNeedReminding.vue'
import Engagements from './components/engagement-components/Engagements.vue'
import PrimeContracts from './components/prime-contracts-components/PrimeContracts.vue'
import ServiceRecords from './components/service-record-components/ServiceRecords.vue'
import CompanyCampaignChangeRequests from './components/company-campaign-change-request-components/CompanyCampaignChangeRequests.vue'
import CRMResolution from "./components/crm-resolution/CRMResolution.vue";
import CRMReporting from "./components/crm-reporting/CRMReporting.vue";
import HighPriorityCompanies from "./components/high-priority-companies/HighPriorityCompanies.vue";

const routes = [
  {path: '/', component: GuestWelcome, name: 'guest-welcome', meta: {requiresAuth: true, pageTitle: "Home", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  // {path: '/companies', component: Companies, name:'companies', meta: {requiresAuth: true}},
  {path: '/add_multiple_companies', component: AddMultipleCompanies, name:"add-multiple-companies", meta: {requiresAuth: true, pageTitle: "Add Multiple Companies", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: '/companies', component: ConfigurableCompanies , name:'configurable-companies', meta: {requiresAuth: true, pageTitle: "Companies", viewMenuConfig: {hasShowAllHideAll: true, hasFilters: true, hasBulkOps: true, hasStatusSummary: true, module: 'company'}}},
  // {path: '/companies_with_campaigns', component: CompaniesWithCampaigns, name:"companies-with-campaigns", meta: {requiresAuth: true}},
  // {path: '/companies_for_client_review', component: CompaniesForClientReview, name:"companies-for-client-review", meta: {requiresAuth: true}},
  {path: '/pdf_tier_view', component: PDFTierView, name:"pdf-tier-view", meta: {requiresAuth: true, pageTitle: "PDF Tier View", viewMenuConfig: {hasShowAllHideAll: true, hasFilters: true, hasBulkOps: false, hasStatusSummary: false, module: 'company'}}},
  {path: '/dla_qpl_companies', component: DLACompanies, name:"dla-qpl-companies", meta: {requiresAuth: true, pageTitle: "DLA QPL Companies", viewMenuConfig: {hasShowAllHideAll: true, hasFilters: true, hasBulkOps: false, hasStatusSummary: false, module: 'company'}}},
  {path: '/create_page_config', component: CreatePageConfig, name:"create-page-config", meta: {requiresAuth: true, pageTitle: "Create Page Config", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: '/manage_page_config/:configId', component: ManagePageConfig, name:"manage-page-config", meta: {requiresAuth: true, pageTitle: "Manage Page Config", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: '/campaign_activities_summary', component: CampaignCrmStatusSummary, name:"campaign-crm-status-summary", meta: {requiresAuth: true, pageTitle: "Campaign CRM Status Summary", viewMenuConfig: {hasShowAllHideAll: true, hasFilters: true, hasBulkOps: false, hasStatusSummary: false, module: 'company'}}},
  {path: '/campaign_activities/:campaignId', component: CampaignActivitiesSummary, name:"campaign-activity-summary", meta: {requiresAuth: true, pageTitle: "Campaign Activity Summary", viewMenuConfig: {hasShowAllHideAll: true, hasFilters: true, hasBulkOps: true, hasStatusSummary: true, module: 'company'}}},
  {path: '/new_crm/:campaignId', component: NewCrm, name:"new_crm", meta: {requiresAuth: true, pageTitle: "New CRM", viewMenuConfig: {hasShowAllHideAll: true, hasFilters: true, hasBulkOps: true, hasStatusSummary: true, module: 'company'}}},
  {path: '/clients', component: Clients, name:'clients', meta: {requiresAuth: true, pageTitle: "Clients", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: true, hasBulkOps: false, hasStatusSummary: false, module: 'client'}}},
  {path: '/referrers', component: ReferrersPage, name:'referrers', meta: {requiresAuth: true, pageTitle: "Referrers", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: true, hasBulkOps: false, hasStatusSummary: false, module: 'referrals'}}},
  {path: '/contacts', component: Contacts, name:'contacts', meta: {requiresAuth: true, pageTitle: "Contacts", viewMenuConfig: {hasShowAllHideAll: true, hasFilters: true, hasBulkOps: false, hasStatusSummary: false, module: 'contact'}}},
  {path: '/auth', component: UserAuth, name: 'login', meta: {requiresUnauth: true, pageTitle: "Login", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: '/project_assignments', component: ProjectAssignmentPage, name: 'project_assignments', meta: {requiresAuth: true, pageTitle: "Project Assignments", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: true, hasBulkOps: false, hasStatusSummary: false, module: 'research'}}},
  {path: '/:pathMatch(.*)*', component: PageNotFound, name:"page_not_found", meta: {requiresAuth: true, pageTitle: "Page Not Found", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: '/research_guide', component: ResearchGuide, name:"research-guide", meta: {requiresAuth: true, pageTitle: "Research Guide", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: '/training_documents', component: TrainingDocuments, name:"training-documents", meta: {requiresAuth: true, pageTitle: "Training Documents", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: "/research_projects", component: ResearchProjects, name: "research-projects", meta: { requiresAuth: true, pageTitle: "Research Projects", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: true, hasBulkOps: false, hasStatusSummary: false, module: 'research'}}},
  {path: "/campaigns", component: Campaigns, name: "campaigns", meta: { requiresAuth: true, pageTitle: "Campaigns", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: true, hasBulkOps: false, hasStatusSummary: false, module: 'campaigns'}}},
  {path: "/email_templates", component: EmailTemplates, name: "email-templates", meta: {requiresAuth: true , pageTitle: "Email Templates", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: "/create_email_template", component: CreateEmailTemplate, name: "create-email-template", meta: {requiresAuth: true, pageTitle: "Create Email Template", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: "/edit_email_template/:templateId", component: EditEmailTemplate, name: "edit-email-template", meta: {requiresAuth: true, pageTitle: "Edit Email Template", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: "/pma_list", component: PartsManufacturerApprovals, name: "parts_manufacturer_approvals", meta: { requiresAuth: true , pageTitle: "PMA", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: true, hasBulkOps: false, hasStatusSummary: false, module: 'research'}}},
  {path: '/businesstypes_sectors_subsectors', component: BusinessTypesSectorsSubSectors, name: "businesstypes-sectors-subsectors", meta: {requiresAuth: true, pageTitle: "Business Types/Sectors/Subsectors", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: '/emails_received/:clientId', component: EmailsReceived, name:"emails-received", meta: {requiresAuth: true, pageTitle: "Emails Received", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: '/quick_file_client_emails', component: SuggestedCompaniesForEmails, name:"quick-file-client-emails", meta: {requiresAuth: true, pageTitle: "Quick File Client Emails", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: "/timecards", component: TimecardMainPage, name: "timecards", meta: { requiresAuth: true, pageTitle: "Timecards", viewMenuConfig: {hasShowAllHideAll: true, hasFilters: true, hasBulkOps: false, hasStatusSummary: false, module: 'timecards'}}},
  {path: "/job_queue", component: JobQueuePage, name: "job-queue", meta: {requiresAuth: true, pageTitle: "Job Queue", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: true, hasBulkOps: false, hasStatusSummary: false, module: 'asynchronousJobs'}}},
  {path: "/maintenance_page", component: MaintenancePage, name: "maintenance-page", meta: {requiresAuth: false, pageTitle: "Maintenance Page", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: "/client_appendices", component: ClientAppendices, name: "client-appendices", meta: {requiresAuth: true, pageTitle: "Client Appendices", viewMenuConfig: {hasShowAllHideAll: true, hasFilters: true, hasBulkOps: false, hasStatusSummary: false, module: 'company'}}},
  {path: "/all_tasks", component: AllTasks, name: "all-tasks", meta: {requiresAuth: false, pageTitle: "All Tasks", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: true, hasBulkOps: false, hasStatusSummary: false, module: 'research'}}},
  {path: "/crm_for_pdf", component: CRMForPDF, name: "crm-for-pdf", meta: {requiresAuth: true, pageTitle: "CRM for PDF", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: true, hasBulkOps: false, hasStatusSummary: false, module: 'campaigns'}}},
  {path: "/instructional_videos", component: InstructionalVideos, name: "instructional-videos", meta: {requiresAuth: false, pageTitle: "Instructional Videos", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: "/admin", component: Admin, name: "admin", meta: {requiresAuth: false, pageTitle: "Admin Tools", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: '/upload_companies', component: UploadCompanies, name: 'upload-companies', meta: { requiresAuth: true, pageTitle: "Upload Companies", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: "/admin_import_tools", component: AdminImportTools, name: "admin-import-tools", meta: {requiresAuth: false, pageTitle: "Admin Import Tools", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: "/campaign_change_report", component: CampaignChangeReport, name: "campaign-change-report", meta: {requiresAuth: true, pageTitle: "Campaign Change Report", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: "/invoices", component: Invoices, name: "invoices", meta: {requiresAuth: true, pageTitle: "Invoices", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: "/invoice_reminders", component: InvoicesThatNeedReminding, name: "invoice-reminders", meta: {requiresAuth: true, pageTitle: "Invoice Reminders", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: "/engagements", component: Engagements, name: "engagements", meta: {requiresAuth: true, pageTitle: "Engagements", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: "/prime_contracts", component: PrimeContracts, name: "prime-contracts", meta: {requiresAuth: true, pageTitle: "Prime Contracts", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: "/service-records", component: ServiceRecords, name: "service-records", meta: {requiresAuth: true, pageTitle: "Service Records", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: "/company-campaign-change-requests", component: CompanyCampaignChangeRequests, name: "company-campaign-change-requests", meta: {requiresAuth: true, pageTitle: "Company Campaign Change Requests", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: "/crm-resolution", component: CRMResolution, name: "crm-resolution", meta: {requiresAuth: true, pageTitle: "CRM Resolution", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: "/crm-reporting", component: CRMReporting, name: "crm-reporting", meta: {requiresAuth: true, pageTitle: "CRM Reporting", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
  {path: "/high-priority-companies", component: HighPriorityCompanies, name: "high-priority-companies", meta: {requiresAuth: true, pageTitle: "High Priority Companies", viewMenuConfig: {hasShowAllHideAll: false, hasFilters: false, hasBulkOps: false, hasStatusSummary: false, module: null}}},
]


const router = createRouter({
  history: createWebHistory(),
  routes,
});

function hasAdvancedPermissions() {
  let permissions = store.getters["auth/showUserPermissions"]

  return (permissions.isAccountManager || permissions.isManagement || permissions.isSuperuser || permissions.isDev)
}

function hasDevPermissions() {
  let permissions = store.getters["auth/showUserPermissions"]

  return (permissions.isDev)
}

const requiresAdvancedPermissions = ['clients', 'referrers', 'parts_manufacturer_approvals'];
const requiresDev = ['admin'];

router.beforeEach(async function (to, _, next) {
  // console.log('router before each')
  if (!store.getters["auth/isAuthenticated"]) {
    // console.log('trying to log in')
    await store.dispatch("auth/tryLogin");
  }
  if (requiresAdvancedPermissions.includes(to.name) && !hasAdvancedPermissions()) {
    alert("You do not have permission to view this page")
    next({ name: "guest-welcome" })
  }
  if (requiresDev.includes(to.name) && !hasDevPermissions()) {
    alert("You do not have permission to view this page")
    next({ name: "guest-welcome" })
  }


  if (to.name == "campaign-activity-summary" || to.name == "new_crm") {
    store.dispatch("company/setShowStatusSummaryPaneTrue");
  }
  if (to.meta.requiresAuth && !store.getters["auth/isAuthenticated"]) {
    // console.log('need that auth')
    next("/auth");
  } else if (to.meta.requiresUnauth && store.getters["auth/isAuthenticated"]) {
    next("/");
  } else {
    next();
  }
});

export default router;
