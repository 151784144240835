<template>
    <div>
        <select name="" id="" class="p-1 border border-black w-fit rounded" v-model="crmNextAction" @change="setCRMNextAction">
            <option v-for="option in nextActionOptions" :key="option.value" :value="option.value" >{{ option.label }}</option>
        </select>
    </div>
</template>

<script>
    export default {
        name: "crm-status-cell"
    }
</script>

<script setup>
import { defineProps, defineEmits, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { updateCompany } from '@/services/Company';

    const store = useStore()

    const props = defineProps({
        company: Object
    })

    const crmNextAction = ref(props.company.crm_next_action)

    const nextActionOptions = ref([
        {value: 'None', label: 'None'},
        {value: 'Determine Next Action', label: 'Determine Next Action'},
        {value: 'Scott to Follow-Up', label: 'Scott to Follow-Up'},
        {value: 'Send Initial Email', label:'Send Initial Email' },
        {value: 'Send Another Email', label: 'Send Another Email'},
        {value: 'Send Email on Acquisition', label: 'Send Email on Acquisition'},
        {value: 'Research Correct Email', label: 'Research Correct Email'},
        {value: 'Call', label: 'Call'},
        {value: 'Schedule Meeting', label: 'Schedule Meeting'},
        {value: 'Wait on Response', label: 'Wait on Response'},
        {value: 'Wait for Client Feedback', label: 'Wait for Client Feedback'},
        {value: 'Meet', label: 'Meet'},
        {value: 'Not Now', label: 'Not Now'},
        {value: 'Leave Alone', label: 'Leave Alone'},
        {value: 'Referral Source to Reach Out', label: 'Referral Source to Reach Out'},
        {value: "Send 2nd Email", label: "Send 2nd Email"},
        {value: "Send 3rd Email", label: "Send 3rd Email"},
        {value: "Send 4th Email", label: "Send 4th Email"},
        {value: "Cold Call 1", label: "Cold Call 1"},
        {value: "Cold Call 2", label: "Cold Call 2"},
        {value: "Call again", label: "Call again"},
        {value: "Periodic Check In", label: "Periodic Check In"},
        {value: "Submit IOI/LOI", label: "Submit IOI/LOI"},
    ])

    watch(props, ()=>{
        crmNextAction.value = props.company.crm_next_action
    })

    const emit = defineEmits(['refetch-companies'])

    async function setCRMNextAction(event){
        // console.log('target', event.target.value)
        const data = JSON.stringify({
            crm_next_action: event.target.value
        })
        const token = store.state.auth.token

        await updateCompany(props.company.id, data, token)
        emit('refetch-companies')
    }

</script>

