<template>
    <div v-if="printView" class="flex flex-col w-full h-full py-4 border-y border-gray-200 ">
        <div class="w-full h-full flex flex-col py-4 px-8 border-b border-gray-200 shadow-none">
            <div class="flex text-lg tracking-widest py-4 text-gray-700 font-semibold justify-center">
                Contacted & Responded Targets
            </div>
            <div class="flex justify-center items-center w-full h-5/6">
                <div class="w-1/6">

                </div>
                <div class="flex w-2/3 h-full justify-center items-center">
                    <CRMChart
                        type="doughnut"
                        :options="contactResponseOptions"
                        :data="contactResponseData"
                        :style="chartStyle"
                        @chart-click="handleChartClick"
                    />
                </div>
                <div class="grid grid-cols-1 text-xs text-gray-700 font-semibold tracking-wider py-4 space-y-1 w-1/6">
                    <div class="flex space-x-2 justify-start items-center">
                        <div class="w-5 h-5" :style="`background-color: ${chartColorMap.responded}`">

                        </div>
                        <div class=" whitespace-nowrap">Responded</div>
                    </div>
                    <div class="flex space-x-2 justify-start items-center">
                        <div class="w-5 h-5" :style="`background-color: ${chartColorMap.noResponse}`">

                        </div>
                        <div class=" whitespace-nowrap">No Response</div>
                    </div>
                    <div class="flex space-x-2 justify-start items-center">
                        <div class="w-5 h-5" :style="`background-color: ${chartColorMap.contacted}`">

                        </div>
                        <div class=" whitespace-nowrap">Contacted</div>
                    </div>
                    <div class="flex space-x-2 justify-start items-center">
                        <div class="w-5 h-5" :style="`background-color: ${chartColorMap.notContacted}`">

                        </div>
                        <div class="whitespace-nowrap">Not Contacted</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full h-full flex flex-col">
            <div class="w-full h-full flex flex-col p-4  bg-white "> 
                <div class="flex text-lg tracking-widest py-4 text-gray-700 font-semibold justify-center">
                    {{ contactedCompanies.length }} Contacted Targets By Furthest Action
                </div>
                <div class="grid grid-cols-8 w-full h-full">
                        <div class="flex flex-col col-span-4 h-full w-full pb-[2.05rem]">
                            <div class="w-full h-[24.136%] flex border-gray-400 border-y border-l ">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold bg-white">
                                    <div class="w-5 h-full " :style="`background-color: ${chartColorMap.noResponse}`">

                                    </div>
                                    <div class="flex space-x-2 items-start">
                                        <div class="text-base w-12 flex justify-end">{{  noResponseRate }} </div>
                                        <div>No Response</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full  justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Email 1</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Email 2</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Email 3</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Email 4</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Email Sent</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">LinkedIn DM</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Cold Call Attempt</div>
                                </div>
                            </div>
                            <div class="w-full h-[10.344%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full  " :style="`background-color: ${chartColorMap.respondedCoverage}`">

                                    </div>
                                    <div class="flex space-x-2 items-start">
                                        <div class="text-base w-12 flex justify-end">{{  respondedCoverageRate }} </div>
                                        <div>Responded / Coverage</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Cold Call Success</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Initial Response</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Additional Response</div>
                                </div>
                            </div>
                            <div class="w-full h-[24.136%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full  " :style="`background-color: ${chartColorMap.activeInDialogue}`">

                                    </div>
                                    <div class="flex   space-x-2 whitespace-nowrap items-start">
                                        <div class="text-base w-12 flex justify-end">{{  activeInDialogueRate }} </div>
                                        <div>Active / In Dialogue</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Shedd Call / Zoom</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Client Call / Zoom</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">In-Person / Facility Tour</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">NDA Sent For Review</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">NDA Executed</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Preliminary Info Request Sent</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Active High Priority</div>
                                </div>
                            </div>
                            <div class="w-full h-[3.448%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full" :style="`background-color: ${chartColorMap.iOISubmitted}`">

                                    </div>
                                    <div class="flex  items-center space-x-2">
                                        <div class="text-base w-12 flex justify-end">{{  iOISubmittedRate }} </div>
                                        <div>IOI Submitted</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">IOI Submitted</div>
                                </div>
                            </div>
                            <div class="w-full h-[6.9%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full  " :style="`background-color: ${chartColorMap.underLOI}`">

                                    </div>
                                    <div class="flex space-x-2 items-start">
                                        <div class="text-base w-12 flex justify-end">{{  underLOIRate }} </div>
                                        <div>Under LOI</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">LOI Submitted</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">LOI Executed</div>
                                </div>
                            </div>
                            <div class="w-full h-[3.448%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full " :style="`background-color: ${chartColorMap.other}`">

                                    </div>
                                    <div class="flex  items-center space-x-2">
                                        <div class="text-base w-12 flex justify-end">{{  otherRate }} </div>
                                        <div>Other</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Other</div>
                                </div>
                            </div>
                            <div class="w-full h-[3.448%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full  " :style="`background-color: ${chartColorMap.transactionClosed}`">

                                    </div>
                                    <div class="flex   items-center space-x-2">
                                        <div class="text-base w-12 flex justify-end">{{  transactionClosedRate }} </div>
                                        <div>Transaction Closed</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Transaction Closed</div>
                                </div>
                            </div>
                            <div class="w-full h-[3.448%] flex border-gray-400 border-b">
                            <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                <div class="w-5 h-full  " :style="`background-color: white`">

                                </div>
                                <div class="flex space-x-2 items-start">
                                    <div class="text-base w-12 flex justify-end"></div>
                                    <div></div>
                                </div>
                            </div>
                            <div class="flex flex-col items-end h-full justify-between">
                                <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap"></div>
                            </div>
                        </div>
                            <div class="w-full h-[3.448%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full  " :style="`background-color: ${chartColorMap.notNow}`">

                                    </div>
                                    <div class="flex   items-center space-x-2">
                                        <div class="text-base w-12 flex justify-end">{{  notNowRate }} </div>
                                        <div>Not Now / Keep Warm</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Not Now / Keep Warm</div>
                                </div>
                            </div>
                            <div class="w-full h-[3.448%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full  " :style="`background-color: ${chartColorMap.revisitCut}`">

                                    </div>
                                    <div class="flex   items-center space-x-2">
                                        <div class="text-base w-12 flex justify-end">{{  revisitCutRate }} </div>
                                        <div>Revisit / Cut</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Revisit / Cut</div>
                                </div>
                            </div>
                            <div class="w-full h-[6.896%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full" :style="`background-color: ${chartColorMap.clientTargetPass}`">

                                    </div>
                                    <div class="flex   items-center space-x-2 whitespace-nowrap">
                                        <div class="text-base w-12 flex justify-end">{{  clientTargetPassRate }} </div>
                                        <div>Client / Target Pass</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Client Pass</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Target Pass</div>
                                </div>
                            </div>  
                            <div class="w-full h-[6.896%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full" :style="`background-color: ${chartColorMap.dead }`">

                                    </div>
                                    <div class="flex   items-center space-x-2 whitespace-nowrap">
                                        <div class="text-base w-12 flex justify-end">{{  deadRate }} </div>
                                        <div>Dead</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Dead - Acquired</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Dead - Out of Business</div>
                                </div>
                            </div>                              
                        </div>
                        <div class="flex flex-col col-span-4 justify-center items-center w-full h-full">
                            <CRMChart
                                type="bar"
                                :options="furthestActionOptions"
                                :data="furthestActionData"
                                :style="chartStyle"
                                @chart-click="handleChartClick"
                            />
                        </div>
                    </div>
            </div>
        </div>
    </div>
    <div v-else class="w-full h-full flex border-x border-gray-300  ">
        <div v-if="visibleChartDetails" class="w-full  max-w-[600px] h-full">
            <CRMChartDetails 
                :chart-id="selectedChartID"
                :header="chartDetailsHeader"
                :companies="companiesInChartDetails"
                :num-companies="numCompaniesInChartDetails"
                @hide-chart-details="hideChartDetails"
            />
        </div>
        <div class="grid grid-cols-3 w-full h-full">
            <div class="flex flex-col space-y-4 w-full h-full">
                <div class="w-full h-full flex flex-col py-4 px-8  bg-white ">
                    <div class="flex text-lg tracking-widest py-4 text-gray-700 font-semibold justify-center">
                        Contacted & Responded Targets
                    </div>
                    <div class="text-base 2xl:flex items-center justify-center tracking-wider whitespace-nowrap text-gray-600 pt-0.5 pb-6">
                        {{ numCompanies }} {{ companyLabel }} in {{ numCampaigns }} {{ campaignLabel }}
                    </div>
                    <div class="flex justify-center items-center w-full h-full">
                        <CRMChart
                            type="doughnut"
                            :options="contactResponseOptions"
                            :data="contactResponseData"
                            :style="chartStyle"
                            :printDimensions="[450, 450]"
                            @chart-click="handleChartClick"
                        />
                    </div>
                    <div class="w-full grid grid-cols-1 text-xs text-gray-700 font-semibold tracking-wider py-4 space-y-1">
                        <div class="flex space-x-2 justify-start items-center">
                            <div class="w-5 h-5" :style="`background-color: ${chartColorMap.responded}`">

                            </div>
                            <div class=" whitespace-nowrap">Responded</div>
                        </div>
                        <div class="flex space-x-2 justify-start items-center">
                            <div class="w-5 h-5" :style="`background-color: ${chartColorMap.noResponse}`">

                            </div>
                            <div class=" whitespace-nowrap">No Response</div>
                        </div>
                        <div class="flex space-x-2 justify-start items-center">
                            <div class="w-5 h-5" :style="`background-color: ${chartColorMap.contacted}`">

                            </div>
                            <div class=" whitespace-nowrap">Contacted</div>
                        </div>
                        <div class="flex space-x-2 justify-start items-center">
                            <div class="w-5 h-5" :style="`background-color: ${chartColorMap.notContacted}`">

                            </div>
                            <div class="whitespace-nowrap">Not Contacted</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full h-full flex flex-col col-span-2 space-y-4 ">
                <div class="w-full h-full flex flex-col p-4  bg-white"> 
                    <div class="flex text-lg tracking-widest py-4 text-gray-700 font-semibold justify-center">
                        {{ contactedCompanies.length }} Contacted Targets By Furthest Action
                    </div>
                    <!--Side Color Block Sections -->
                    <div class="grid grid-cols-8 w-full h-full">
                        <div class="flex flex-col col-span-4 h-full w-full pb-[2.05rem]">
                            <div class="w-full h-[24.136%] flex border-gray-400 border-y border-l ">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold bg-white">
                                    <div class="w-5 h-full " :style="`background-color: ${chartColorMap.noResponse}`">

                                    </div>
                                    <div class="flex space-x-2 items-start">
                                        <div class="text-base w-12 flex justify-end">{{  noResponseRate }} </div>
                                        <div>No Response</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full  justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Email 1</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Email 2</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Email 3</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Email 4</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Email Sent</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">LinkedIn DM</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Cold Call Attempt</div>
                                </div>
                            </div>
                            <div class="w-full h-[10.344%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full  " :style="`background-color: ${chartColorMap.respondedCoverage}`">

                                    </div>
                                    <div class="flex space-x-2 items-start">
                                        <div class="text-base w-12 flex justify-end">{{  respondedCoverageRate }} </div>
                                        <div>Responded / Coverage</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Cold Call Success</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Initial Response</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Additional Response</div>
                                </div>
                            </div>
                            <div class="w-full h-[24.136%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full  " :style="`background-color: ${chartColorMap.activeInDialogue}`">

                                    </div>
                                    <div class="flex   space-x-2 whitespace-nowrap items-start">
                                        <div class="text-base w-12 flex justify-end">{{  activeInDialogueRate }} </div>
                                        <div>Active / In Dialogue</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Shedd Call / Zoom</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Client Call / Zoom</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">In-Person / Facility Tour</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">NDA Sent For Review</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">NDA Executed</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Preliminary Info Request Sent</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Active High Priority</div>
                                </div>
                            </div>
                            <div class="w-full h-[3.448%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full" :style="`background-color: ${chartColorMap.iOISubmitted}`">

                                    </div>
                                    <div class="flex  items-center space-x-2">
                                        <div class="text-base w-12 flex justify-end">{{  iOISubmittedRate }} </div>
                                        <div>IOI Submitted</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">IOI Submitted</div>
                                </div>
                            </div>
                            <div class="w-full h-[6.9%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full  " :style="`background-color: ${chartColorMap.underLOI}`">

                                    </div>
                                    <div class="flex space-x-2 items-start">
                                        <div class="text-base w-12 flex justify-end">{{  underLOIRate }} </div>
                                        <div>Under LOI</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">LOI Submitted</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">LOI Executed</div>
                                </div>
                            </div>
                            <div class="w-full h-[3.448%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full " :style="`background-color: ${chartColorMap.other}`">

                                    </div>
                                    <div class="flex  items-center space-x-2">
                                        <div class="text-base w-12 flex justify-end">{{  otherRate }} </div>
                                        <div>Other</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Other</div>
                                </div>
                            </div>
                            <div class="w-full h-[3.448%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full  " :style="`background-color: ${chartColorMap.transactionClosed}`">

                                    </div>
                                    <div class="flex   items-center space-x-2">
                                        <div class="text-base w-12 flex justify-end">{{  transactionClosedRate }} </div>
                                        <div>Transaction Closed</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Transaction Closed</div>
                                </div>
                            </div>
                            <div class="w-full h-[3.448%] flex border-gray-400 border-b">
                            <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                <div class="w-5 h-full  " :style="`background-color: white`">

                                </div>
                                <div class="flex space-x-2 items-start">
                                    <div class="text-base w-12 flex justify-end"></div>
                                    <div></div>
                                </div>
                            </div>
                            <div class="flex flex-col items-end h-full justify-between">
                                <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap"></div>
                            </div>
                        </div>
                            <div class="w-full h-[3.448%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full  " :style="`background-color: ${chartColorMap.notNow}`">

                                    </div>
                                    <div class="flex   items-center space-x-2">
                                        <div class="text-base w-12 flex justify-end">{{  notNowRate }} </div>
                                        <div>Not Now / Keep Warm</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Not Now / Keep Warm</div>
                                </div>
                            </div>
                            <div class="w-full h-[3.448%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full  " :style="`background-color: ${chartColorMap.revisitCut}`">

                                    </div>
                                    <div class="flex   items-center space-x-2">
                                        <div class="text-base w-12 flex justify-end">{{  revisitCutRate }} </div>
                                        <div>Revisit / Cut</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Revisit / Cut</div>
                                </div>
                            </div>
                            <div class="w-full h-[6.896%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full" :style="`background-color: ${chartColorMap.clientTargetPass}`">

                                    </div>
                                    <div class="flex   items-center space-x-2 whitespace-nowrap">
                                        <div class="text-base w-12 flex justify-end">{{  clientTargetPassRate }} </div>
                                        <div>Client / Target Pass</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Client Pass</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Target Pass</div>
                                </div>
                            </div>  
                            <div class="w-full h-[6.896%] flex border-gray-400 border-b border-l">
                                <div class="w-full h-full text-base text-gray-700 tracking-widest flex items-center font-semibold ">
                                    <div class="w-5 h-full" :style="`background-color: ${chartColorMap.dead }`">

                                    </div>
                                    <div class="flex   items-center space-x-2 whitespace-nowrap">
                                        <div class="text-base w-12 flex justify-end">{{  deadRate }} </div>
                                        <div>Dead</div>
                                    </div>
                                </div>
                                <div class="flex flex-col items-end h-full justify-between">
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Dead - Acquired</div>
                                    <div class="flex justify-end items-center text-sm text-gray-500 tracking-wide font-semibold h-full whitespace-nowrap">Dead - Out of Business</div>
                                </div>
                            </div>                              
                        </div>
                        <div class="flex flex-col col-span-4 justify-center items-center w-full h-full">
                            <CRMChart
                                type="bar"
                                :options="furthestActionOptions"
                                :data="furthestActionData"
                                :style="chartStyle"
                                @chart-click="handleChartClick"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-if="visibleChartDetails && selectedChartID == 'furthestAction'" class="w-[500px] h-full">
            <CRMChartDetails 
                :chart-id="selectedChartID"
                :header="chartDetailsHeader"
                :companies="companiesInChartDetails"
                :num-companies="numCompaniesInChartDetails"
                @hide-chart-details="hideChartDetails"
            />
        </div> -->
    </div>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, watch, computed } from 'vue';
import CRMChartDetails from './CRMChartDetails.vue';
import CRMChart from './CRMChart.vue';


const props = defineProps([
    'printView',
    'clientName',
    'campaigns',
    'selectedCompanies',
    'contactedCompanies',
    'notContactedCompanies',
    'respondedCompanies',
    'noResponseCompanies',
])


watch(() => props.contactedCompanies, () => {
    updateContactResponseData()
}, {deep: true})

watch(() => props.notContactedCompanies, () => {
    updateContactResponseData()
}, {deep: true})

watch(() => props.respondedCompanies, () => {
    updateFurthestActionData()
    updateContactResponseData()
}, {deep: true})

watch(() => props.noResponseCompanies, () => {
    updateFurthestActionData()
    updateContactResponseData()
}, {deep: true})

const chartColorMap = reactive({
    hold: '#6B7280',
    notContacted: '#6B7280',
    contacted: '#3E437F',
    noResponse: '#C7D2FE',
    responded: '#25274D',
    respondedCoverage: '#8489C2',
    activeInDialogue: '#5054A5', // '#696FB5',
    iOISubmitted: '#3C3F7C', // '#5158A4',
    underLOI:  '#282B7B', // '#3E437F',
    other: '#373BA9', // '#6B7280',
    transactionClosed: '#0F102E', // '#0F102E', // '#2E9CCA',
    notNow: '#FCD34D',
    revisitCut: '#FCD34D',
    clientTargetPass: '#4B5563',
    dead: '#4B5563',
    lightFont: 'white',
    darkFont: '#25274D',
    lightBackground: 'white',
    darkBackground: '#6B7280',
    lightBorder: '#E5E5E5',
    darkBorder:  '#B0B6BF', // '#9CA3AF',
    axisLabels: '#374151',

})

const selectedChartID = ref(null)
const chartDetailsHeader = ref(null)
const numCompaniesInChartDetails = ref(null)
const companiesInChartDetails = ref([])
const visibleChartDetails = ref(false)


const numCompanies = computed(() => {
    return props.selectedCompanies ? props.selectedCompanies.length : 0
})

const companyLabel = computed(() => {
    if(numCompanies.value == 1) {
        return 'Target'
    }
    return 'Targets'
})

const numCampaigns = computed(() => {
    return props.campaigns ? props.campaigns.length : 0
})

const campaignLabel = computed(() => {
    if(numCampaigns.value == 1) {
        return 'Campaign'
    }
    return 'Campaigns'
})

const noResponseRate = computed(() => {
    if(props.noResponseCompanies) {
        return `${Math.round((props.noResponseCompanies.length / props.contactedCompanies.length) * 100)}%`
    }
    return `0%`
})


const respondedCoverageCompanies = computed(() => {
    if(props.respondedCompanies) {
        return props.respondedCompanies.filter((company) => company.computed_crm_status.label == 'Responded / Coverage')
    }
    return []
})

const respondedCoverageRate = computed(() => {
    if(props.noResponseCompanies) {
        return `${Math.round((respondedCoverageCompanies.value.length / props.contactedCompanies.length) * 100)}%`
    }
    return `0%`
})

const activeInDialogueCompanies = computed(() => {
    if(props.respondedCompanies) {
        return props.respondedCompanies.filter((company) => company.computed_crm_status.label == 'Active - In Dialogue')
    }
    return []
})

const activeInDialogueRate = computed(() => {
    if(props.noResponseCompanies) {
        return `${Math.round((activeInDialogueCompanies.value.length / props.contactedCompanies.length) * 100)}%`
    }
    return `0%`
})

const iOISubmittedCompanies = computed(() => {
    if(props.respondedCompanies) {
        return props.respondedCompanies.filter((company) => company.computed_crm_status.label == 'IOI Submitted')
    }
    return []
})

const iOISubmittedRate = computed(() => {
    if(props.noResponseCompanies) {
        return `${Math.round((iOISubmittedCompanies.value.length / props.contactedCompanies.length) * 100)}%`
    }
    return `0%`
})

const underLOICompanies = computed(() => {
    if(props.respondedCompanies) {
        return props.respondedCompanies.filter((company) => company.computed_crm_status.label == 'Under LOI')
    }
    return []
})

const underLOIRate = computed(() => {
    if(props.noResponseCompanies) {
        return `${Math.round((underLOICompanies.value.length / props.contactedCompanies.length) * 100)}%`
    }
    return `0%`
})


const transactionClosedCompanies = computed(() => {
    if(props.respondedCompanies) {
        return props.respondedCompanies.filter((company) => company.computed_crm_status.label == 'Transaction Closed')
    }
    return []
})

const transactionClosedRate = computed(() => {
    if(props.noResponseCompanies) {
        return `${Math.round((transactionClosedCompanies.value.length / props.contactedCompanies.length) * 100)}%`
    }
    return `0%`
})

const notNowCompanies = computed(() => {
    if(props.respondedCompanies) {
        return props.respondedCompanies.filter((company) => company.computed_crm_status.label == 'Not Now / Keep Warm')
    }
    return []
})

const notNowRate = computed(() => {
    if(props.noResponseCompanies) {
        return `${Math.round((notNowCompanies.value.length / props.contactedCompanies.length) * 100)}%`
    }
    return `0%`
})

const revisitCutCompanies = computed(() => {
    if(props.respondedCompanies) {
        return props.respondedCompanies.filter((company) => company.computed_crm_status.label == 'Revisit / Cut')
    }
    return []
})

const revisitCutRate = computed(() => {
    if(props.noResponseCompanies) {
        return `${Math.round((revisitCutCompanies.value.length / props.contactedCompanies.length) * 100)}%`
    }
    return `0%`
})

const clientTargetPassCompanies = computed(() => {
    if(props.respondedCompanies) {
        return props.respondedCompanies.filter((company) => company.computed_crm_status.label == 'Client / Target Pass')
    }
    return []
})

const clientTargetPassRate = computed(() => {
    if(props.noResponseCompanies) {
        return `${Math.round((clientTargetPassCompanies.value.length / props.contactedCompanies.length) * 100)}%`
    }
    return `0%`
})

const deadCompanies = computed(() => {
    if(props.respondedCompanies) {
        return props.respondedCompanies.filter((company) => company.computed_crm_status.label == 'Dead')
    }
    return []
})

const deadRate = computed(() => {
    if(props.noResponseCompanies) {
        return `${Math.round((deadCompanies.value.length / props.contactedCompanies.length) * 100)}%`
    }
    return `0%`
})

const otherCompanies = computed(() => {
    if(props.respondedCompanies) {
        return props.respondedCompanies.filter((company) => company.furthest_crm_action.action_id == 19)
    }
    return []
})

const otherRate = computed(() => {
    if(props.noResponseCompanies) {
        return `${Math.round((otherCompanies.value.length / props.contactedCompanies.length) * 100)}%`
    }
    return `0%`
})


function filterFurthestAction(company, actionId) {
    if(company.furthest_crm_action) {
        if(company.furthest_crm_action.action_id == actionId) {
            return true
        }
    }
    return false
}

const contactResponseData = reactive({
    datasets: [ 
        {
            circumference: 360 * (props.contactedCompanies.length / (props.contactedCompanies.length + props.notContactedCompanies.length)),
            backgroundColor: [chartColorMap.noResponse, chartColorMap.responded],
            color: ['white'],
            data: [props.noResponseCompanies.length, props.respondedCompanies.length],
            companies: [props.noResponseCompanies, props.respondedCompanies],
            label: 'Response Rates',
            labels: ['No Response', 'Responded'],
            divisor: props.contactedCompanies.length
        },
        {
            backgroundColor: [chartColorMap.contacted, chartColorMap.notContacted],
            color: ['white'],
            data: [props.contactedCompanies.length, props.notContactedCompanies.length],
            companies: [props.contactedCompanies, props.notContactedCompanies],
            label: 'Contact Rates',
            labels: ['Contacted', 'Not Contacted'],
            divisor: numCompanies.value,
        },

    ],
})

const contactResponseOptions = reactive({
    responsive: true,
    maintainAspectRatio: false,
    cutout: '30%',
    id: 'Contacted & Responsed',
    plugins: {
        centerText: {
            display: true,
            text: `${numCompanies.value}`,
            fontSize: 48,
            color: chartColorMap.darkFont,
            fontFamily: "Poppins",
        },
        datalabels: {
            color: function (context) {
                if(context.dataIndex == 0 && context.datasetIndex == 0) {
                    return chartColorMap.darkFont
                }
                return chartColorMap.lightFont
            },
            font: {
                weight: 'bold',
                size: 16,
            },
            formatter: function(value, context) {
                if(value !== 0) {
                    const label = value;
                    const subLabel = `${Math.round((value / context.dataset.divisor) * 100)}%`;
                    return `${label}\n(${subLabel})`;
                } else {
                    return null
                }
            }
        //     color: function(context) {
        //     return context.dataset.backgroundColor[context.dataIndex];
        //   },
        },
        legend: null,
        tooltip: {
            callbacks: {
                label: (context) => {
                    const dataset = context.dataset;
                    const dataIndex = context.dataIndex;
                    let label = dataset.labels[dataIndex] + ': ' +  dataset.data[dataIndex];
                    let sublabel = `${Math.round((dataset.data[dataIndex] / dataset.divisor) * 100)}%`

                    return `${label}\n${sublabel}`;
                }
            }
        }
    },
})

const chartStyle = reactive({
    color: chartColorMap.lightBackground,
})

const furthestActionData = reactive({
    labels: [
        'Email 1', 
        'Email 2', 
        'Email 3', 
        'Email 4',
        'Email Sent',
        'LinkedInDM',
        'Cold Call Attempt',
        'Cold Call Success',
        'Initial Response',
        'Additional Response',
        'Shedd Call / Zoom',
        'Client Call / Zoom',
        'In-Person / Facility Tour',
        'NDA Sent for Review',
        'NDA Executed',
        'Prelim Info Request Sent',
        'Active High Priority',
        'IOI Submitted',
        'LOI Submitted',
        'LOI Executed', 
        'Other',
        'Transaction Closed',
        '',
        'Not Now / Keep Warm',
        'Revisit / Cut',
        'Client Pass',
        'Target Pass',
        'Dead - Acquired',
        'Dead - Out of Business'
    ],
    datasets: [ 
        {
            labels: [
                'Email 1', 
                'Email 2', 
                'Email 3', 
                'Email 4',
                'Email Sent',
                'LinkedInDM',
                'Cold Call Attempt',
                'Cold Call Success',
                'Initial Response',
                'Additional Response',
                'Shedd Call / Zoom',
                'Client Call / Zoom',
                'In-Person / Facility Tour',
                'NDA Sent for Review',
                'NDA Executed',
                'Prelim Info Request Sent',
                'Active High Priority',
                'IOI Submitted',
                'LOI Submitted',
                'LOI Executed',
                'Other',
                'Transaction Closed',
                '',
                'Not Now / Keep Warm',
                'Revisit / Cut',
                'Client Pass',
                'Target Pass',
                'Dead - Acquired',
                'Dead - Out of Business'
            ],
            backgroundColor: [
                chartColorMap.noResponse, chartColorMap.noResponse, chartColorMap.noResponse, chartColorMap.noResponse, chartColorMap.noResponse, chartColorMap.noResponse, chartColorMap.noResponse,  
                chartColorMap.respondedCoverage, chartColorMap.respondedCoverage, chartColorMap.respondedCoverage,
                chartColorMap.activeInDialogue, chartColorMap.activeInDialogue, chartColorMap.activeInDialogue, chartColorMap.activeInDialogue, chartColorMap.activeInDialogue, chartColorMap.activeInDialogue, chartColorMap.activeInDialogue,
                chartColorMap.iOISubmitted, 
                chartColorMap.underLOI, chartColorMap.underLOI,
                chartColorMap.other,
                chartColorMap.transactionClosed,
                chartColorMap.lightFont,
                chartColorMap.notNow,
                chartColorMap.revisitCut,
                chartColorMap.clientTargetPass, chartColorMap.clientTargetPass,
                chartColorMap.dead, chartColorMap.dead
            ],
            color: [chartColorMap.lightFont],
            data: [
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 2)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 3)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 4)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 21)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 1)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 7)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 5)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 6)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 8)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 22)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 9)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 10)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 11)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 12)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 13)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 14)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 24)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 15)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 16)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 17)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 19)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 18)}).length,
                0,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 25)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 26)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 20)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 27)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 28)}).length,
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 29)}).length,
            ],
            companies: [
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 2)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 3)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 4)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 21)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 1)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 7)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 5)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 6)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 8)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 22)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 9)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 10)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 11)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 12)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 13)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 14)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 24)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 15)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 16)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 17)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 19)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 18)}),
                [],
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 25)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 26)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 20)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 27)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 28)}),
                props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 29)}),
            ],
            divisor: props.contactedCompanies.length
        }
    ],
})

const furthestActionOptions = reactive({
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    categoryPercentage: 0.98,
    barPercentage: 0.98,
    id: 'Furthest Action',
    scales: {
        x: {
            beginAtZero: true,
            ticks: {
                font: {
                    size: 16,
                    weight: 'bold',
                    color: chartColorMap.axisLabels,
                }
            },
            grid: {
                color: function (context) {
                    // console.log('x axis color context', context)
                    // return 'blue'
                    if(context.tick) {
                        if (context.index == 0 ) {
                            return chartColorMap.darkBorder
                        } else {
                            return chartColorMap.lightBorder
                        }
                    }
                    return chartColorMap.darkBorder
                }
            }

        },
        y: {
            stacked: false,
            grid: {
                color: function (context) {
                    // console.log('y axis context', context)
                    // return 'blue'
                    // console.log(' axis color context', context)
                    if(context.tick) {
                        if (context.index == 0 ||
                            context.tick.value == 7 ||
                            context.tick.value == 10 ||
                            context.tick.value == 17 ||
                            context.tick.value == 18 ||
                            context.tick.value == 20 ||
                            context.tick.value == 21 ||
                            context.tick.value == 22 ||
                            context.tick.value == 23 ||
                            context.tick.value == 24 ||
                            context.tick.value == 25 ||
                            context.tick.value == 27 ||
                            context.index == 29
                        ) {
                            return chartColorMap.darkBorder
                        } else {
                            return chartColorMap.lightBorder
                        }
                    }
                    return chartColorMap.darkBorder
                }
                
            },
            ticks: {
                display: false,
                font: {
                    size: 16,
                    weight: 'bold',
                    color: chartColorMap.axisLabels
                }
            },

        }
    },
    plugins: {
        datalabels: {
            color: function (context) {
                // console.log('datalabel color context', context)
                if(context.dataIndex < 8) {
                    return chartColorMap.darkFont
                }
                return chartColorMap.lightFont
            },
            font: {
                weight: 'bold',
                size: 14,
            },
            formatter: function(value, context) {
                if(value !== 0) {
                    const label = value;
                    if(value < 2) {
                        return label
                    } else {
                        const subLabel = `${Math.round((value / context.dataset.divisor) * 100)}%`;
                        return `${label} (${subLabel})`;
                    }

                } else {
                    return null
                }

            }
        },
        legend: {
            display: false
        },
        removeGridLines: {},
    },
})

function showChartDetails() {
    visibleChartDetails.value = true
}

function hideChartDetails() {
    visibleChartDetails.value = false
}


function handleChartClick(chartId, datasetIndex, dataIndex, dataValue) {
    console.log('handleChartClick')
    console.log('Clicked on:', chartId, datasetIndex, dataIndex, 'with value:', dataValue);
    let chartData = null
    if(chartId == 'Contacted & Responsed') {
        chartData = contactResponseData
    } else if(chartId == 'Furthest Action') {
        chartData = furthestActionData
    }

    if(chartData) {
        selectedChartID.value = chartId
        chartDetailsHeader.value = chartData.datasets[datasetIndex].labels[dataIndex]
        companiesInChartDetails.value = chartData.datasets[datasetIndex].companies[dataIndex]
        numCompaniesInChartDetails.value = dataValue
    }
    showChartDetails()
}


function updateFurthestActionData() {
    furthestActionData.datasets[0].data = [
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 2)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 3)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 4)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 21)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 1)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 7)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 5)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 6)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 8)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 22)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 9)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 10)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 11)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 12)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 13)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 14)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 24)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 15)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 16)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 17)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 19)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 18)}).length,
        0,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 25)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 26)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 20)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 27)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 28)}).length,
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 29)}).length,
    ]
    furthestActionData.datasets[0].companies = [
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 2)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 3)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 4)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 21)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 1)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 7)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 5)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 6)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 8)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 22)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 9)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 10)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 11)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 12)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 13)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 14)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 24)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 15)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 16)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 17)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 19)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 18)}),
        [],
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 25)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 26)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 20)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 27)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 28)}),
        props.selectedCompanies.filter((company) => {return filterFurthestAction(company, 29)}),
    ]
}

function updateContactResponseData() {
    contactResponseData.datasets[0].data = [props.noResponseCompanies.length, props.respondedCompanies.length]
    contactResponseData.datasets[0].companies = [props.noResponseCompanies, props.respondedCompanies]
    contactResponseData.datasets[1].data = [props.contactedCompanies.length, props.notContactedCompanies.length]
    contactResponseData.datasets[1].companies = [props.contactedCompanies, props.notContactedCompanies]
    contactResponseData.datasets[0].circumference = 360 * (props.contactedCompanies.length / (props.contactedCompanies.length + props.notContactedCompanies.length))
    contactResponseData.datasets[0].divisor = props.contactedCompanies.length
    contactResponseData.datasets[1].divisor = numCompanies.value
    contactResponseOptions.plugins.centerText.text = `${numCompanies.value}`
}

</script>
