<template>
  <div class="h-full w-full">
    <form @submit.prevent="submitForm(true)" action="" class="h-full w-full">
      <base-form class="h-[90%] w-full">
        <template #body>
          <div class="h-full">
            <base-accordion>
              <template #title>
                <div>Basic Research</div>
              </template>
              <template #accordian-body>
                <div class="grid grid-cols-4 gap-2">
                  <div class="flex flex-col">
                    <label for="companyName">Company*</label>
                    <input class="p-1 border mt-1 flex-col border-gray-400 rounded-md justify-between" type="text" maxlength="255" id="companyName" required="true"
                          v-model="companyName">
                  </div>
                  <div class="flex flex-col">
                    <label for="doingBusinessAs">Doing Business As</label>
                    <input class="p-1 border mt-1 border-gray-400 rounded-md" type="text" maxlength="255" id="doingBusinessAs" v-model="doingBusinessAs">
                  </div>
                  <div class="flex flex-col">
                    <label for="doingBusinessAs">Company Nickname</label>
                    <input class="p-1 border mt-1 border-gray-400 rounded-md" type="text" maxlength="255" id="companyNickname" v-model="companyNickname">
                  </div>
                  <div class="flex flex-col">
                    <div class="flex space-x-2 items-center">
                      <label for="websiteUrl">Website</label>
                      <button @click.prevent="addHttp" class="border border-dark-primary rounded px-1 hover:text-white hover:border-white hover:bg-dark-primary hover:underline text-xs">Add http://</button>
                    </div>
                    <input class="p-1 border mt-1 border-gray-400 rounded-md" type="url" maxlength="200" placeholder="http://www.example.com" id="websiteUrl" v-model="websiteUrl">
                  </div>
                  <div class="flex flex-col">
                    <div class="grid grid-cols-2 gap-2">
                      <div class="flex flex-col">
                        <label for="ziId">ZoomInfo ID</label>
                        <!-- <input class="p-1 border mt-1 border-gray-400 rounded-md" type="tel" maxlength="50" placeholder="1234567" id="ziId" v-model="zoomInfoId"> -->
                        <div class="bg-gray-200 p-1 border mt-1 border-gray-400 rounded-md">{{ zoomInfoId }}</div>
                      </div>
                      <div class="flex flex-col">
                        <label for="everaxisPillar">EverAxis Pillar</label>
                        <select id="everaxisPillar" v-model="everaxisPillar"
                                class="bg-white border-gray-400 rounded-md p-1 border mt-1  text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                          <option value=null>-------</option>
                          <option value="P1: Peers">P1: Peers</option>
                          <option value="P2: EverAxis in Motion">P2: EverAxis in Motion</option>
                          <option value="P3: Forever Connecting">P3: Forever Connecting</option>
                      </select>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <label for="everaxisPillar">IGP Category</label>
                    <select id="everaxisPillar" v-model="igpCategory"
                            class="bg-white border-gray-400 rounded-md p-1 border mt-1  text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option :value=null>-------</option>
                      <option value="Elastomer">Elastomer</option>
                      <option value="HMI">HMI</option>
                      <option value="Insulation">Insulation</option>
                      <option value="M&I">M&I</option>
                      <option value="Materials">Materials</option>
                      <option value="Microwave/RF">Microwave/RF</option>
                      <option value="Oscillators">Oscillators</option>
                      <option value="Other">Other</option>
                      <option value="Photonics/Optics">Photonics/Optics</option>
                      <option value="Valves, Actuators, Pumps">Valves, Actuators, Pumps</option>
                  </select>
                  </div>
                  <div class="flex flex-col">
                    <label for="cageCode">CAGE Code</label>
                    <input class="p-1 border mt-1 border-gray-400 rounded-md" type="text" maxlength="5" id="cageCode" v-model="cageCode">
                  </div>
                  <div class="flex flex-col">
                    <label for="ueiSam">UEI</label>
                    <input class="p-1 border mt-1 border-gray-400 rounded-md" type="text" id="ueiSam" v-model="ueiSam">
                  </div>
                  <div class="flex flex-col">
                    <label for="phone">Company General Line</label>
                    <input class="p-1 border mt-1 border-gray-400 rounded-md" type="tel" maxlength="50" placeholder="(123) 456-7890" id="phone" v-model="phone">
                  </div>
                  <div class="flex flex-col">
                    <label for="address">Address</label>
                    <input class="p-1 border mt-1 border-gray-400 rounded-md" type="text" maxlength="255" id="address" v-model="address">
                  </div>
                  <div class="flex flex-col">
                    <label for="city">City</label>
                    <input class="p-1 border mt-1 border-gray-400 rounded-md" type="text" maxlength="255" id="city" v-model="city">
                  </div>
                  <div class="flex flex-col">
                    <div class="grid grid-cols-2 gap-2">
                      <div class="flex flex-col">
                        <label for="state">State</label>
                        <input class="p-1 border mt-1 border-gray-400 rounded-md" type="text" maxlength="50" id="state" v-model="state">
                      </div>
                      <div class="flex flex-col">
                        <label for="zip">Zip</label>
                        <input class="p-1 border mt-1 border-gray-400 rounded-md" type="text" maxlength="20" id="zip" v-model="zip">
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <label for="country">Country</label>
                    <input class="p-1 border mt-1 border-gray-400 rounded-md" type="text" maxlength="255" id="country" v-model="country">
                  </div>
                  <div class="flex flex-col">
                    <label for="rawAddress">Raw Address</label>
                    <input class="p-1 border mt-1 border-gray-400 rounded-md" type="text" id="rawAddress" v-model="rawAddress">
                  </div>
                  <div class="flex flex-col">
                    <div class="grid grid-cols-2 gap-2">
                      <div class="flex flex-col">
                        <label for="revenue">Revenue (M) - {{formattedRevenueDate}}</label>
                        <input class="p-1 border mt-1 border-gray-400 rounded-md" type="number" step="0.01" placeholder="0.00" id="revenue" v-model="revenue">
                      </div>
                      <div class="flex flex-col">
                        <label for="revenueSource">Revenue Source</label>
                        <input class="p-1 border mt-1 border-gray-400 rounded-md" type="text" maxlength="255" placeholder="Source" id="revenueSource" v-model="revenueSource">
                      </div> 
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <div class="grid grid-cols-2 gap-2">
                      <div class="flex flex-col">
                        <label for="ebitda">EBITDA</label>
                        <input class="p-1 border mt-1 border-gray-400 rounded-md" type="number" step="0.01" placeholder="0.00" id="ebitda" v-model="ebitda">
                      </div>
                      <div class="flex flex-col">
                        <label for="headcount">Headcount</label>
                        <input class="p-1 border mt-1 border-gray-400 rounded-md" type="number" placeholder="0" id="headcount" v-model="headcount">
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <label for="squareFootage">Sq. Feet (K)</label>
                    <input class="p-1 border mt-1 border-gray-400 rounded-md" type="number" step="0.1" placeholder="0.0" id="squareFootage" v-model="squareFootage">
                  </div>
                  <div class="flex flex-col">
                    <label for="category">Category</label>
                    <input class="p-1 border mt-1 border-gray-400 rounded-md" type="text"  placeholder="Category" id="category" v-model="category">
                  </div>
                  <div class="flex flex-col">
                    <div class="grid grid-cols-2 gap-2">
                      <div class="flex flex-col">
                        <label for="reasonCannotAcquire">Reason Cannot Acquire</label>
                        <select id="reasonCannotAcquire" v-model="reasonCannotAcquire"
                                class=" p-1 border mt-1 border-gray-400 rounded-md text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                          <option value=null>-------</option>
                          <option value="Client">Client</option>
                          <option value="Government Entity">Government Entity</option>
                          <option value="Large Private Equity Owned">Large Private Equity Owned</option>
                          <option value="Native American Owned">Native American Owned</option>
                          <option value="Nonprofit">Nonprofit (NPO)</option>
                          <option value="Public">Public</option>
                          <option value="Other">Other</option>
                      </select>
                      </div>
                      <div class="flex flex-col">
                        <label for="doNotResearchReason">Do Not Research Reason:</label>
                        <input class="bg-gray-200 p-1 border mt-1 border-gray-400 rounded-md" type="text" id="doNotResearchReason" v-model="doNotResearchReason" disabled>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <label for="archiveReason">Archive Reason</label>
                    <select id="archiveReason" v-model="archiveReason"
                            class="p-1 border mt-1 border-gray-400 rounded-md text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option value=null>-------</option>
                      <option value="Permanently Closed">Permanently Closed</option>
                      <option value="Not Aerospace">Not Aerospace</option>
                      <option value="Does Not Exist">Does Not Exist</option>
                    </select>
                  </div>
                  <div class="flex flex-col">
                    <label for="acquirer">Acquirer</label>
                    <input class="p-1 border mt-1 border-gray-400 rounded-md" type="text" maxlength="255" id="acquirer" v-model="acquirer">
                  </div>
                  <div class="flex flex-col">
                    <label for="acquisitionDate">Acquisition Date</label>
                    <input class="p-1 border mt-1 border-gray-400 rounded-md" type="date" id="acquisitionDate" v-model="acquisitionDate">
                  </div>
                  <div class="flex flex-col">
                    <label for="parentCompany">Parent Company</label>
                    <input class="p-1 border mt-1 border-gray-400 rounded-md" type="text" maxlength="255" id="parentCompany" v-model="parentCompany">
                  </div>
                  <div class="flex flex-col">
                    <label for="investors">Investors</label>
                    <input class="p-1 border mt-1 border-gray-400 rounded-md" type="text" maxlength="255" id="investors" v-model="investors">
                  </div>
                  <div class="flex flex-col">
                    <label for="customers">Customers</label>
                    <textarea class="p-1 border mt-1 border-gray-400 rounded-md" rows="3" id="customers" v-model="customers"></textarea>
                  </div>
                  <div class="flex flex-col">
                    <label for="platforms">Platforms</label>
                    <textarea class="p-1 border mt-1 border-gray-400 rounded-md" rows="3" id="platforms" v-model="platforms"></textarea>
                  </div>
                  <div class="flex flex-col">
                    <label for="problemCompanyNotes">Problem Company Notes</label>
                    <textarea class="p-1 border mt-1 border-gray-400 rounded-md" rows="3" id="problemCompanyNotes"
                              v-model="problemCompanyNotes"></textarea>
                  </div>
                  <div class="flex flex-col">
                    <label for="alternateAddresses">Alternate Addresses</label>
                    <textarea class="p-1 border mt-1 border-gray-400 rounded-md" rows="3" id="alternateAddresses"
                              v-model="alternateAddresses"></textarea>
                  </div>
                  <div class="flex flex-col col-span-2">
                    <label for="description">Description <CompanyDescriptionAggregator @refetch-companies="fetchNewCompanies" :company="company"/> </label>
                    <textarea class="p-1 border mt-1 border-gray-400 rounded-md" rows="3" id="description" v-model="description"></textarea>
                  </div>
                  <div class="flex flex-col col-span-2">
                    <label for="researcherDataNotes">Researcher Data Notes</label>
                    <textarea class="p-1 border mt-1 border-gray-400 rounded-md" rows="3" id="researcherDataNotes" v-model="researcherDataNotes"></textarea>
                  </div>
                  <div class="flex flex-col col-span-2">
                    <label for="mergeConflicts">Merge Conflicts</label>
                    <textarea class="p-1 border mt-1 border-gray-400 rounded-md" rows="3" id="mergeConflicts" v-model="mergeConflicts"></textarea>
                  </div>
                  <div v-if="company" class="grid grid-cols-2 gap-2">
                    <div class="flex flex-col">
                      <label for="sourceOwner">Source Owner</label>
                      <select id="sourceOwner" v-model="sourceOwner"
                            class="p-1 border mt-1 border-gray-400 rounded-md text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value="null">------------</option>
                            <option v-for="sourceOwner in sourceOwners" :key="sourceOwner.id" :value="sourceOwner">{{ sourceOwner }}</option>
                      </select>
                    </div>
                    <div class="flex flex-col">
                      <div v-if="urlVettedBy" class="flex flex-col">
                        <div>
                          <p class="pb-1">URL Vetted By {{urlVettedByName}}.</p>
                        </div>
                        <base-pill-button @click.prevent="vetURLAction('remove_url_vetted')" type="submit">
                          Remove URL Vetted
                        </base-pill-button>
                      </div>
                      <div v-else class="flex flex-col">
                        <base-pill-button v-if="!websiteUrl" @click.prevent="vetURLAction('mark_no_url_vetted')" type="submit">Mark No URL</base-pill-button>
                        <base-pill-button v-if="websiteUrl && !urlVettedBy" @click.prevent="vetURLAction('mark_url_vetted')" type="submit">Mark
                          URL Vetted
                        </base-pill-button>
                      </div>
                      <p class="pt-1 pb-1 text-xs">*This action will not save changes to other fields in this form.</p>
                    </div>
                  </div>
                  <div v-else class="grid col-span-2 grid-cols-2 gap-2">
                    <div class="flex flex-col">
                      <label for="sourceOwner">Source Owner</label>
                      <select id="sourceOwner" v-model="sourceOwner"
                            class="p-1 border mt-1 border-gray-400 rounded-md text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value="null">------------</option>
                            <option v-for="sourceOwner in sourceOwners" :key="sourceOwner.id" :value="sourceOwner">{{ sourceOwner }}</option>
                      </select>
                    </div>
                    <div class="flex flex-col">
                      <label for="source">Source*</label>
                      <!-- <select id="source" required="true" v-model="source"
                        class="p-1  border mt-1 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="null">------------</option>
                        <option v-for="source in sources" :key="source.id" :value="source.id">{{ source.source_name }}</option>
                      </select> -->
                      <select required="true" @click.prevent="setSourceOptions"  class="border border-gray-400 rounded-md p-1 mt-1 w-full" v-model="source" name="" id="">
                          <option v-for="source in sourceOptions" :key="source.value" :value="source.value">
                              {{ source.label }}
                          </option>
                      </select>
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <div v-if="researchComplete===1" class="flex flex-col">
                      <div>
                        <p class="pb-1">Research is Complete.</p>
                      </div>
                      <base-pill-button class="text-xs" id="researchComplete" @click.prevent="promoteOrDemote('demote')" type="submit">Demote</base-pill-button>
                      <p class="pt-1 pb-1 text-xs">*This action will not save changes to other fields in this form.</p>    
                    </div>
                    <div v-else-if="researchComplete===0" class="flex flex-col">
                      <div>
                        <p class="pb-1">Research is Incomplete.</p>
                      </div>
                      <div v-if="urlVettedBy" class="flex flex-col">
                        <div class="flex space-x-1">
                          <base-pill-button  class="text-xs w-1/2" id="researchComplete" @click.prevent="promoteOrDemote('promote')" type="submit">Promote</base-pill-button>
                          <base-pill-button  class="text-xs w-1/2" id="researchComplete" @click.prevent="promoteOrDemote('halt')" type="submit">Halt</base-pill-button>
                        </div>
                        <p class="pt-1 pb-1 text-xs">*This action will not save changes to other fields in this form.</p>                 
                      </div>
                      <p v-else>Vet URL to Mark Research Complete.</p>
                    </div>
                    <div v-else-if="researchComplete===-1" class="flex flex-col">
                      <div>
                        <p class="pb-1">Research is Halted.</p>
                      </div>
                      <div v-if="urlVettedBy" class="flex flex-col">
                        <div class="flex flex-row space-x-1">
                          <base-pill-button  class="text-xs w-1/2" id="researchComplete" @click.prevent="promoteOrDemote('demote')" type="submit">Resume</base-pill-button>
                          <base-pill-button  class="text-xs w-1/2" id="researchComplete" @click.prevent="promoteOrDemote('promote')" type="submit">Promote</base-pill-button>
                        </div>
                        <p class="pt-1 pb-1 text-xs">*This action will not save changes to other fields in this form.</p>                 
                      </div>
                      <p v-else>Vet URL to Mark Research Complete.</p>
                    </div>
                  </div>
                </div>
              </template>
            </base-accordion>

            <base-accordion>
              <template #title>
                <div>Account Management</div>
              </template>
              <template #accordian-body>
                <div class="grid grid-cols-4 gap-2">
                  <div class="flex flex-col">
                    <label for="campaign">Campaign</label>
                    <div class="bg-gray-200 p-1 border mt-1 border-gray-400 rounded-md">
                      {{ company ? company.campaign ? company.campaign.campaign_name : '--' : '--' }}
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <label for="campaign_selection">Request Campaign Change</label>
                    <div class="flex space-x-1" v-if="numCampaignChangeRequests > 0">
                      <div class="bg-gray-200 p-1 border mt-1 w-full border-gray-400 rounded-md">
                        {{ requestedCampaign }}
                      </div>
                    </div>
                    <div class="flex flex-col" v-else>
                        <select id="campaign_selection" :class="{'bg-green-200' : requested_campaign_change_id}" v-model="requested_campaign_change_id"
                                class="p-1 border mt-1 border-gray-400 rounded-md text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                          <option :value=null>-------</option>
                          <option value=None>Remove from campaign</option>
                          <option v-for="campaign in campaigns" :key="campaign.id" :value="campaign.id">
                            {{ campaign.campaign_name }}
                          </option>
                        </select>
                        <textarea v-model=campaignChangeRequestReason :class="{'bg-green-200' : campaignChangeRequestReason}" name="" placeholder="Change request reason" class="p-1 border mt-1 border-gray-400 rounded-md" id="" cols="3" rows="1"></textarea>
                        <!-- <base-button-affirmative @click.prevent="submitCampaignChangeRequest" class="h-full mt-1">
                          Request
                        </base-button-affirmative> -->
                      <!-- </div> -->
                    </div>
                  </div>
                  <div class="flex flex-col ">
                    <label for="relevantBusiness">Relevant Business</label>
                    <input class="p-1 border mt-1 border-gray-400 rounded-md" type="text" maxlength="255" id="relevantBusiness" v-model="relevantBusiness">
                  </div>
                  <div class="flex flex-col">
                    <label for="clientFeedback">Client Feedback</label>
                    <select id="clientFeedback" v-model="clientFeedback"
                            class="p-1 border mt-1 border-gray-400 rounded-md text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option value=null>-------</option>
                      <option value="approved">Approved</option>
                      <option value="excluded">Excluded</option>
                      <option value="hold">Hold</option>
                      <option value="pass">Pass</option>
                      <option value="research_further">Research Further</option>
                    </select>
                  </div>
                  <div></div>
                  <div class="flex flex-col">
                    <label for="accountManagerResearchNotes">Account Manager Research Notes</label>
                    <textarea class="p-1 border mt-1 border-gray-400 rounded-md" rows="3" id="accountManagerResearchNotes"
                              v-model="accountManagerResearchNotes"></textarea>
                  </div>
                  <div class="flex flex-col ">
                    <label for="furtherResearchNeeded">Further Research Needed</label>
                    <textarea class="p-1 border mt-1 border-gray-400 rounded-md" rows="3" id="furtherResearchNeeded"
                              v-model="furtherResearchNeeded"></textarea>
                  </div>
                  <div class="flex flex-col ">
                    <label for="clientFeedbackNotes">Client Feedback Notes</label>
                    <textarea class="p-1 border mt-1 border-gray-400 rounded-md" rows="3" id="clientFeedbackNotes"
                              v-model="clientFeedbackNotes"></textarea>
                  </div>
                  <div class="flex flex-col ">
                    <label for="clientUpdateNotes">Client Update Notes</label>
                    <textarea class="p-1 border mt-1 border-gray-400 rounded-md" rows="3" id="clientUpdateNotes"
                              v-model="clientUpdateNotes"></textarea>
                  </div>
                </div>
              </template>
            </base-accordion>

            <base-accordion>
              <template #title>
                <div>Customer Relationship Management</div>
              </template>
              <template #accordian-body>
                <div class="grid grid-cols-4 gap-2">
                  <div class="flex flex-col gap-2 col-span-2">
                    <div class="flex flex-col ">
                      <label for="outreachNotes">Outreach Notes</label>
                      <textarea class="p-1 border mt-1 border-gray-400 rounded-md" rows="3" id="alternateAddresses"
                        v-model="outreachNotes"></textarea>
                    </div>
                  </div>
                  <div class="flex flex-col gap-2 col-span-2">
                    <div class="flex flex-col ">
                      <label for="personalizedOutreachText">Personalized Outreach Text</label>
                      <textarea class="p-1 border mt-1 border-gray-400 rounded-md" rows="3" id="personalizedOutreachText"
                        v-model="personalizedOutreachText"></textarea>
                    </div>
                  </div>
                  <div class="flex flex-col gap-2 col-span-2">
                    <div v-if="company && company.resolved_crm_summary" class="flex flex-col ">
                      <label for="crmSummary">CRM Summary</label>
                      <CalculatedCRMSummary 
                        :crm-actions="companyCRMActions"
                        :current-campaign="currentCampaign"
                      />
                    </div>
                    <div v-else class="flex flex-col ">
                      <label for="crmSummary">CRM Summary</label>
                      <textarea class="p-1 border mt-1 border-gray-400 rounded-md" rows="3" id="crmSummary" v-model="crmSummary"></textarea>
                    </div>
                  </div>
                </div>
              </template>
            </base-accordion>
          </div>
        </template>
        <template #footer>
          <div class="flex flex-row justify-center items-center mb-4 mt-2">
            <base-button-affirmative
            class="mx-1"
                type="submit">Save and Close
            </base-button-affirmative>
            <button
            class="
        rounded-md
        px-1
        py-1
        border
        border-black
        hover:shadow-md
        active:text-white
        active:bg-accent-secondary
        focus:text-white
        focus:bg-accent-secondary
        text-xs
        cursor-pointer
        mx-1
        sm:text-xs
        sm:h-fit
      "
                @click.prevent="submitForm(false)">Save
            </button>
            <base-button-negative type="button"
              class="mx-1"
                    data-bs-dismiss="modal" @click="close">
              Cancel
            </base-button-negative>
          </div>
        </template>
      </base-form>
    </form>
  </div>
</template>

<script>
import { vetURL, promoteOrDemoteResearch, assignCampaign } from '@/services/Company'
import CompanyDescriptionAggregator from './company/CompanyDescriptionAggregator.vue';
import { getSources } from '@/services/Source';
import { useToast } from 'vue-toast-notification';
import CalculatedCRMSummary from './company/CalculatedCRMSummary.vue';

export default {
  name: "company-form",
  props: ["company", "campaigns", "sources", "sourceOwners", "formCleared"],
  components: {CompanyDescriptionAggregator, CalculatedCRMSummary},
  data() {
    return {
      companyName: this.company ? this.company.company_name: null,
      address: this.company ? this.company.address : null,
      city: this.company ? this.company.city : null,
      websiteUrl: this.company ? this.company.website_url : null,
      revenue: this.company ? this.company.revenue : null,
      revenueLastUpdatedDate: this.company ? this.company.revenue_last_updated_date : null,
      revenueSource: this.company ? this.company.revenue_source : null,
      ebitda: this.company ? this.company.ebitda : null,
      headcount: this.company ? this.company.employee_headcount : null,
      squareFootage: this.company ? this.company.square_footage : null,
      state: this.company ? this.company.state : null,
      zip: this.company ? this.company.zip : null,
      country: this.company ? this.company.country : null,
      phone: this.company ? this.company.phone : null,
      acquirer: this.company ? this.company.acquirer : null,
      requested_campaign_change_id: null,
      source: "",
      sourceOwner: null,
      companySources: null,
      acquisitionDate: this.company ? this.company.acquisition_date : null,
      reasonCannotAcquire: this.company ? this.company.reason_cannot_acquire : null,
      doNotResearchReason: this.company ? this.company.do_not_research_reason : null,
      archiveReason: this.company ? this.company.archive_reason : null,
      doingBusinessAs: this.company ? this.company.doing_business_as : null,
      parentCompany: this.company ? this.company.parent_company : null,
      investors: this.company ? this.company.investors : null,
      mergeConflicts: this.company ? this.company.merge_conflicts : null,
      description: this.company ? this.company.description : null,
      accountManagerResearchNotes: this.company ? this.company.account_manager_research_notes : null,
      clientFeedbackNotes: this.company ? this.company.client_feedback_notes : null,
      alternateAddresses: this.company ? this.company.alternate_addresses : null,
      clientFeedback: this.company ? this.company.client_feedback : null,
      furtherResearchNeeded: this.company ? this.company.further_research_needed : null,
      crmSummary: this.company ? this.company.crm_summary : null,
      customers: this.company ? this.company.customers : null,
      platforms: this.company ? this.company.platforms : null,
      outreachNotes: this.company ? this.company.out_reach_notes : null,
      rawAddress: this.company ? this.company.raw_address : null,
      category: this.company ? this.company.category : null,
      // zoomInfoId: this.company ? this.company.zoom_info_id : null,
      problemCompanyNotes: this.company ? this.company.problem_company_notes : null,
      relevantBusiness: this.company ? this.company.relevant_business : null,
      researcherDataNotes: this.company ? this.company.researcher_data_notes : null,
      clientUpdateNotes: this.company ? this.company.client_update_notes : null,
      everaxisPillar: this.company ? this.company.everaxis_pillar : null,
      igpCategory: this.company ? this.company.igp_category : null,
      cageCode: this.company ? this.company.cage_code : null,
      ueiSam: this.company ? this.company.unique_entity_identifier_sam : null,
      companyNickname: this.company ? this.company.company_nickname : null,
      personalizedOutreachText: this.company ? this.company.personalized_outreach_text : null,
      campaignChangeRequestReason: null,
      sourceOptions: [{value: null, label: '------'}],
    }
  },
  mounted: function () {
    if(this.company) {
      // console.log('company', this.company.campaign_change_requests[0])
      this.requested_campaign_change_id = this.company.campaign_change_requests.length > 0 ? this.company.campaign_change_requests[0].new_campaign_id : null
      // console.log('requested', this.requested_campaign_change_id)
    } else {
      this.requested_campaign_change_id = null
    }
    if(this.company) {
      this.companySources = this.company.sources ? this.company.sources : null
    } else {
      this.companySources = null
    }
    if(this.company) {
      this.sourceOwner = this.company.source_owner ? this.company.source_owner : null
    } else {
      this.sourceOwner = "Unassigned"
    }
  },
  watch: {
    urlVettedByName() {
    },
    urlVettedBy() {
    },
    researchComplete() {
    },
    formCleared() {
        this.clearFields()
        this.$emit('set-form-cleared-false')
    }, company : function (newCompany, oldCompany) {
        this.companyName = newCompany.company_name
        this.address = newCompany.address
        this.city = newCompany.city
        this.websiteUrl = newCompany.website_url
        this.revenue = newCompany.revenue
        this.revenueLastUpdatedDate = newCompany.revenue_last_updated_date
        this.revenueSource = newCompany.revenue_source
        this.ebitda = newCompany.ebitda
        this.headcount = newCompany.employee_headcount
        this.squareFootage = newCompany.square_footage
        this.state = newCompany.state
        this.zip = newCompany.zip
        this.country = newCompany.country
        this.phone = newCompany.phone
        this.acquirer = newCompany.acquirer
        this.requested_campaign_change_id = newCompany.campaign_change_requests > 0 ? newCompany.campaign_change_requests[0].new_campaign_id : null
        this.sourceOwner = newCompany.source_owner
        this.companySources = newCompany.sources
        this.acquisitionDate = newCompany.acquisition_date
        this.reasonCannotAcquire = newCompany.reason_cannot_acquire
        this.doNotResearchReason = newCompany.do_not_research_reason
        this.archiveReason = newCompany.archive_reason
        this.doingBusinessAs = newCompany.doing_business_as
        this.parentCompany = newCompany.parent_company
        this.investors = newCompany.investors
        this.mergeConflicts = newCompany.merge_conflicts
        this.description = newCompany.description
        this.accountManagerResearchNotes = newCompany.account_manager_research_notes
        this.clientFeedbackNotes = newCompany.client_feedback_notes
        this.alternateAddresses = newCompany.alternate_addresses
        this.clientFeedback = newCompany.client_feedback
        this.furtherResearchNeeded = newCompany.further_research_needed
        this.crmSummary = newCompany.crm_summary
        this.customers = newCompany.customers
        this.platforms = newCompany.platforms
        this.outreachNotes = newCompany.out_reach_notes
        this.rawAddress = newCompany.raw_address
        this.category = newCompany.category
        this.problemCompanyNotes = newCompany.problem_company_notes
        this.relevantBusiness = newCompany.relevant_business
        this.researcherDataNotes = newCompany.researcher_data_notes
        this.clientUpdateNotes = newCompany.client_update_notes
        this.everaxisPillar = newCompany.everaxis_pillar
        this.igpCategory = newCompany.igp_category
        this.cageCode = newCompany.cage_code
        this.ueiSam = newCompany.unique_entity_identifier_sam
        this.companyNickname = newCompany.company_nickname
        this.personalizedOutreachText = newCompany.personalized_outreach_text
        this.campaignChangeRequestReason = null
        this.requested_campaign_change_id = this.company.campaign_change_requests.length > 0 ? this.company.campaign_change_requests[0].new_campaign_id : null

    }
    
  },
  methods: {
    async submitForm(shouldClose = true) {
      const formData = {
        company_name: this.companyName,
        address: this.address,
        city: this.city,
        website_url: this.websiteUrl,
        revenue: this.revenue,
        revenue_source: this.revenueSource,
        ebitda: this.ebitda,
        employee_headcount: this.headcount,
        square_footage: this.squareFootage,
        state: this.state,
        zip: this.zip,
        country: this.country,
        phone: this.phone,
        acquirer: this.acquirer,
        acquisition_date: this.acquisitionDate !== '' ? this.acquisitionDate : null,
        reason_cannot_acquire: this.reasonCannotAcquire,
        archive_reason: this.archiveReason,
        doing_business_as: this.doingBusinessAs,
        parent_company: this.parentCompany,
        investors: this.investors,
        merge_conflicts: this.mergeConflicts,
        description: this.description,
        account_manager_research_notes: this.accountManagerResearchNotes,
        client_feedback_notes: this.clientFeedbackNotes,
        alternate_addresses: this.alternateAddresses,
        client_feedback: this.clientFeedback,
        further_research_needed: this.furtherResearchNeeded,
        crm_summary: this.crmSummary,
        customers: this.customers,
        platforms: this.platforms,
        out_reach_notes: this.outreachNotes,
        raw_address: this.rawAddress,
        category: this.category,
        // zoom_info_id: this.zoomInfoId,
        problem_company_notes: this.problemCompanyNotes,
        relevant_business: this.relevantBusiness,
        researcher_data_notes: this.researcherDataNotes,
        // campaign_id: this.campaign_id,
        source: this.source,
        source_owner: this.sourceOwner,
        everaxis_pillar: this.everaxisPillar,
        igp_category: this.igpCategory,
        client_update_notes: this.clientUpdateNotes,
        cage_code: this.cageCode,
        unique_entity_identifier_sam: this.ueiSam,
        personalized_outreach_text: this.personalizedOutreachText,
        company_nickname: this.companyNickname,
      }
      if(this.requested_campaign_change_id && !this.requestedCampaign){
        // this.submitCampaignChangeRequest()
        let campaignChangeData = {
          campaign_id: this.requested_campaign_change_id,
          change_request_reason: this.campaignChangeRequestReason
        }
        this.$emit('submit-form', formData, shouldClose, campaignChangeData)
      } else {
        this.$emit('submit-form', formData, shouldClose)
      }
    },
    addHttp(){
      if(!this.websiteUrl.startsWith("http://") && !this.websiteUrl.startsWith("https://")){
        this.websiteUrl = "http://" + this.websiteUrl
      }
    },
    fetchNewCompanies() {
        this.$emit('refetch-companies')
    },
    async vetURLAction(action) {
      const token = this.$store.getters['auth/showToken']
      await vetURL(this.company.id, action, token)
      this.fetchNewCompanies()
    },
    async promoteOrDemote(action) {
      const token = this.$store.getters['auth/showToken']
      await promoteOrDemoteResearch(this.company.id, action, token)
      this.fetchNewCompanies()
    },
    async setSourceOptions() {
      const token = this.$store.getters['auth/showToken']
      const results = await getSources(token)
      this.sourceOptions = [
        {value: "", label: '------'}, ...results.map((source) => {
            return {value: source.id, label: source.source_name}
        })
    ]
    },
    close() {
      this.$emit('close');
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(date);
    },
    clearFields() {
      this.companyName = null
      this.address = null
      this.city = null
      this.websiteUrl = null
      this.revenue = null
      this.revenueLastUpdatedDate = null
      this.revenueSource = null
      this.ebitda = null
      this.headcount = null
      this.squareFootage = null
      this.state = null
      this.zip = null
      this.country = null
      this.phone = null
      this.acquirer = null
      // this.campaign_id = null
      this.source = ""
      this.sourceOwner = null
      this.companySources = null
      this.acquisitionDate = null
      this.reasonCannotAcquire = null
      this.doNotResearchReason = null
      this.archiveReason = null
      this.doingBusinessAs = null
      this.parentCompany = null
      this.investors = null
      this.mergeConflicts = null
      this.description = null
      this.accountManagerResearchNotes = null
      this.clientFeedbackNotes = null
      this.alternateAddresses = null
      this.clientFeedback = null
      this.furtherResearchNeeded = null
      this.crmSummary = null
      this.customers = null
      this.platforms = null
      this.outreachNotes = null
      this.rawAddress = null
      this.category = null
      // this.zoomInfoId = null
      this.problemCompanyNotes = null
      this.relevantBusiness = null
      this.researcherDataNotes = null
      this.clientUpdateNotes = null
      this.everaxisPillar = null
      this.cageCode = null
      this.ueiSam = null
      this.personalizedOutreachText = null
      this.companyNickname = null
      this.sourceOptions = [{value: null, label: '------'}]
      this.campaignChangeRequestReason = null
    }
  },
  computed: {
    formattedRevenueDate() {
      try {
        return this.formatDate(this.revenueLastUpdatedDate)
      } catch {
        return 'None'
      }

    },
    urlVettedBy() {
      return this.company ? this.company.url_vetted_by : null
    },
    requestedCampaign() {
      if(this.company.campaign_change_requests.length > 0 && this.campaigns){
        console.log(this.company.campaign_change_requests)
        const campaign = this.campaigns.filter((campaign) => {
          return campaign.id == this.requested_campaign_change_id
        })[0]
      if(campaign){
        return campaign.campaign_name
      } else {
        return "Remove from campaign"
      }
      } else {
        return null
      }
    },
    currentCampaign() {
      if(this.company) {
        return this.company.campaign ? this.company.campaign.id : null
      }
      return null
    },
    numCampaignChangeRequests() {
      if(this.company) {
       return this.company.campaign_change_requests.length
      }
      return 0
    },
    researchComplete() {
      return this.company ? this.company.research_complete : null
    },
    urlVettedByName() {
      if(this.urlVettedBy){
        return `${this.company.url_vetted_by.first_name} ${this.company.url_vetted_by.last_name}`
      } else {
        return ''
      }
    },
    zoomInfoId() {
      if(this.company) {
        if(this.company.zoom_info_company) {
          return this.company.zoom_info_company.zoom_info_id
        } else {
          return 'No Zoom Info ID'
        }
      } else {
        return ''
      }
    },
    companyCRMActions() {
      if(this.company) {
        return this.company.crm_actions
      }
      return []
    }
  }


}
</script>