<template>
    <!-- Tabbar -->
    <div class="h-full w-full">
    <transition
            enter-active-class="transition duration-400 ease-in"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-100 ease-out"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
            class="h-full"
        >
            <div v-if="isLoading" class="fixed top-0 bottom-0 left-0 right-0 bg-black/[0.7] flex flex-col justify-center items-center z-40"
                    tabindex="-1" >
                <div class="flex flex-col justify-center items-center p-4 rounded-md">
                <svg aria-hidden="true" class="mx-1 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#d3d3d3"/>
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#2E9CCA"/>
                </svg>
                <div class="text-white p-4 whitespace-nowrap text-sm">
                    Please Wait While We Make Changes To {{ company.company_name }}.
                </div>
                </div>
            </div>
        </transition>
        <div class="pt-1 w-full">
            <BaseTabBar 
                :tabList="tabList"
                :selectedColor="selectedColor"
                :notSelectedColor="notSelectedColor"
                :hoverColor="hoverColor"
                @set-page="setView"
            />
        </div>
        <!-- Edit Company View -->
        <div v-show="displayEditForm()" class="px-2 overscroll-none h-full">
            <div class="flex flex-row justify-end items-center">
                <!-- <CompanyQuickLinks :company="company" /> -->
                <CompanySources :company="company" />
                <CompanyNAICSCodes :company="company" />
            </div>
            <!-- <div v-if="!tooManySources" class="flex flex-row justify-between items-center">
                <CompanyQuickLinks :company="company" />
                <CompanySources class="whitespace-nowrap truncate" :company="company" />
            </div> -->
            <!-- <div v-if="tooManySources" class="flex flex-col justify-between items-start white">
                <CompanyQuickLinks :company="company" />
                <CompanySources :company="company" />
            </div> -->
            <CompanyForm
                @submit-form="updateCurrentCompany"
                @refetch-companies="fetchNewCompanies"
                @submit-campaign-change-request="submitCampaignChangeRequest"
                @close="close"
                :company="company"
                :campaigns="campaigns"
                :sourceOwners="sourceOwners"
            />
        </div>
        <!-- History of Edits View -->
        <div v-show="displayHistory()" class="px-2 py-1 overflow-y-scroll h-[95%]">
            <CompanyHistory
                :company="props.company"
                class="mt-3"
            />
        </div>
        <!-- <div v-show="displayHistory()" class="px-2 py-1 overflow-y-scroll overscroll-none">
            <CompanyHistory
                :company="props.company"
                class="mt-3"
            />
        </div> -->
        <!-- Campaigns History View -->
        <div v-if="displayPrevCampaigns()" class="px-2 py-1 overflow-y-scroll overscroll-none h-[95%]">
            <CompanyPreviousCampaigns
                :company="props.company"
                :previousCampaigns="previousCampaigns"
                class="mt-3"
            />
        </div>
        <!-- Contracts View -->
        <div v-if="displayContracts()" class="px-2 py-1 overflow-y-scroll overscroll-none h-[95%]">
            <CompanyContractsAndSubContracts
                :company="props.company"
                class="mt-3"
            />
        </div>
        <!-- Contacts View -->
        <div v-if="displayContacts()" class="px-2 overflow-y-scroll overscroll-none h-[95%] ">
            <AllContactsList
                :company="company"
                @refetch-companies="fetchNewCompanies"
            />
        </div>
        <!-- Collections View -->
        <div v-if="displayCollections()" class="px-2 h-[95%] ">
            <CompanyCollections
                :company="company"
                @refetch-companies="fetchNewCompanies"
            />
        </div>
        <div v-if="displayResearchPosts()" class="px-2 h-[95%] ">
            <CompanyResearch
                :company="company"
                @refetch-companies="fetchNewCompanies"
            />
        </div>
        <div v-if="displayCapabilities()" class="px-2 h-[95%] ">
            <CompanyCapabilityForm
                :company="company"
                @refetch-companies="fetchNewCompanies"
            />
        </div>
        <div v-if="displayThirdPartyData()" class="px-2 h-[95%] ">
            <CompanyThirdPartyData
                :company="company"
                @refetch-companies="fetchNewCompanies"
            />
        </div>
    </div>
</template>

<script>
    export default {
    name: "edit-company",
    components: { BaseTabBar }
}
</script>

<script setup>
import BaseTabBar from './BaseTabBar.vue';
import CompanySources from './CompanySources.vue';
import CompanyNAICSCodes from './company/CompanyNAICSCodes.vue'
import CompanyForm from './CompanyForm.vue';
import CompanyHistory from './CompanyHistory.vue';
import CompanyPreviousCampaigns from './CompanyPreviousCampaigns.vue';
import CompanyContractsAndSubContracts from './CompanyContractsAndSubContracts.vue';
import AllContactsList from './company-cell-components/AllContactsList.vue';
import CompanyCollections from '@/components/CompanyCollections.vue';
import CompanyResearch from '@/components/company/CompanyResearch.vue';
import CompanyCapabilityForm from './CompanyCapabilityForm.vue';
import CompanyZoomInfoForm from './zoom-info/CompanyZoomInfoForm.vue';
import CompanyThirdPartyData from './CompanyThirdPartyData.vue';
import { ref, defineEmits, defineProps, computed} from 'vue';
import { useStore } from 'vuex';
import { updateCompany, assignCampaign } from '@/services/Company';
import { getActiveCampaigns } from '@/services/Campaign';
import { getSourceOwners } from '@/services/SourceOwner';
import { getCompanyPreviousCampaigns } from '@/services/CampaignHistory'
import { useToast } from 'vue-toast-notification';

const props = defineProps({
    company: Object,
    campaigns: Array, // This prop isn't used; not sure why it's here
    defaultTab: String
})
const $toast = useToast()
const store = useStore()
// const campaigns = ref(props.campaigns)
const sourceOwners = ref([])
const previousCampaigns = ref([])


const emit = defineEmits(['close', 'refetch-companies'])
const tooManySources = ref(false)
const isLoading = ref(false)


async function updateCurrentCompany(data, close, campaignChangeData=null) {
    const token = store.state.auth.token
    isLoading.value = true
    const submitData = {
        company_data: data,
        campaign_change_data: campaignChangeData
    }
    const result = await updateCompany(props.company.id, submitData, token)
    // console.log('result', result)
    isLoading.value = false
    if(result.status == 200) {
        $toast.open({
            message: `Successfully Updated Company: ${props.company.id} - ${props.company.company_name}.`,
            type: 'success',
            duration: 20000
        })
        if(result.data.campaign_change_status && result.data.campaign_change_status.status == 'success'){
            $toast.open({
            message: `${result.data.campaign_change_status.message}.`,
            type: 'success',
            duration: 20000
        })
        }
        else if(result.data.campaign_change_status && result.data.campaign_change_status.status == 'existing_request'){
            $toast.open({
            message: `${result.data.campaign_change_status.message}.`,
            type: 'error',
            duration: 20000
        })
        }
        if(close){
            emit('close')
        }
        emit('refetch-companies')
    } else {
        $toast.open({
            message: `Could Not Update Company: ${props.company.id} - ${props.company.company_name}.`,
            type: 'error',
            duration: 20000
        })
    }

}

async function loadSourceOwners(){
    const token = store.state.auth.token
    const result = await getSourceOwners(token)
    sourceOwners.value = result
}

function close() {
    emit('close')
}

function fetchNewCompanies() {
    emit('refetch-companies')
}

function sourceWrap() {
    if (companySources.value.length > 75) {
        tooManySources.value = true;
    }
}

const companySources = computed(() => {
    if(props.company.sources) {
        let sources = ''
        for(const source of props.company.sources) {
            sources += `${source.source_name}, `
        }
        sources = sources.substring(0, sources.length - 2);
        // console.log(sources)
        return sources
    } else {
        return '--'
    }
})

async function loadPreviousCampaigns() {
    const payload = {
      token: store.state.auth.token,
      companyId: props.company.id
    }
    const result = await getCompanyPreviousCampaigns(payload)
    if (result.status == 200) {
        previousCampaigns.value = result.data
    }
  }

// Tab Control
const tabList = ref([
    {tabName: 'Edit Company', isSelected: true},
    {tabName: 'Campaigns', isSelected: false},
    {tabName: 'Capabilities', isSelected: false},
    {tabName: 'Collections', isSelected: false},
    {tabName: 'Contacts', isSelected: false},
    {tabName: 'Research', isSelected: false},
    {tabName: 'Contracts', isSelected: false},
    {tabName: 'History', isSelected: false},
    {tabName: '3rd Party Data', isSelected: false}
    
])
const selectedColor = 'white';
const notSelectedColor = 'gray-100'
const hoverColor = 'slate-50'

function setView(tabName) {
    if (tabName == 'Edit Company') {
        showEditCompany();
    } else if (tabName == 'Campaigns') {
        showPrevCampaigns();
    } else if (tabName == 'History') {
        showHistory();
    } else if (tabName == 'Contracts') {
        showContracts();
    } else if (tabName == 'Contacts') {
        showContacts();
    } else if (tabName == 'Collections') {
        showCollections();
    } else if (tabName == 'Research') {
        showResearchPosts();
    }else if (tabName == 'Capabilities'){
        showCapabilities();
    } else if (tabName == '3rd Party Data') {
        showThirdPartyData();
        // showZoomInfo();
    } else {
        console.log("Error")
    }
}

const isHistoryVisible = ref(false);
const isPrevCampaignsVisible = ref(false)
const isContractsVisible = ref(false)
const isContactsVisible = ref(false)
const isCollectionsVisible = ref(false)
const isResearchPostsVisible = ref(false)
const isCapabilitiesVisible = ref(false)
const isThirdPartyDataVisible = ref(false)
// const isZoomInfoVisible = ref(false)

function showEditCompany() {
    isHistoryVisible.value = false;
    isContractsVisible.value = false
    isContactsVisible.value = false
    isPrevCampaignsVisible.value = false;
    isCollectionsVisible.value = false;
    isResearchPostsVisible.value = false;
    isCapabilitiesVisible.value = false;
    isThirdPartyDataVisible.value = false;
    tabList.value.forEach((tab) => {
        if(tab.tabName === "Edit Company"){
            tab.isSelected = true
        } else {
            tab.isSelected = false
        }
    })
}

function showHistory() {
    isHistoryVisible.value = true;
    isPrevCampaignsVisible.value = false;
    isContractsVisible.value = false;
    isContactsVisible.value = false;
    isCollectionsVisible.value = false;
    isResearchPostsVisible.value = false;
    isCapabilitiesVisible.value = false;
    isThirdPartyDataVisible.value = false;
    tabList.value.forEach((tab) => {
        if(tab.tabName === "History"){
            tab.isSelected = true
        } else {
            tab.isSelected = false
        }
    })
}

function showPrevCampaigns() {
    isHistoryVisible.value = false;
    isContractsVisible.value = false;
    isContactsVisible.value = false;
    isPrevCampaignsVisible.value = true;
    isCollectionsVisible.value = false;
    isResearchPostsVisible.value = false;
    isCapabilitiesVisible.value = false;
    isThirdPartyDataVisible.value = false;
    tabList.value.forEach((tab) => {
        if(tab.tabName === "Campaigns"){
            tab.isSelected = true
        } else {
            tab.isSelected = false
        }
    })
}
function showContracts() {
    isHistoryVisible.value = false;
    isPrevCampaignsVisible.value = false;
    isContactsVisible.value = false;
    isContractsVisible.value = true;
    isCollectionsVisible.value = false;
    isResearchPostsVisible.value = false;
    isCapabilitiesVisible.value = false;
    isThirdPartyDataVisible.value = false;
    tabList.value.forEach((tab) => {
        if(tab.tabName === "Contracts"){
            tab.isSelected = true
        } else {
            tab.isSelected = false
        }
    })

}
function showContacts() {
    // console.log('show contacts')
    isHistoryVisible.value = false;
    isPrevCampaignsVisible.value = false;
    isContractsVisible.value = false;
    isContactsVisible.value = true;
    isCollectionsVisible.value = false;
    isResearchPostsVisible.value = false;
    isCapabilitiesVisible.value = false;
    isThirdPartyDataVisible.value = false;
    tabList.value.forEach((tab) => {
        if(tab.tabName === "Contacts"){
            tab.isSelected = true
        } else {
            tab.isSelected = false
        }
    })
}

function showCollections() {
    // console.log('show collections')
    isHistoryVisible.value = false;
    isPrevCampaignsVisible.value = false;
    isContractsVisible.value = false;
    isContactsVisible.value = false;
    isCollectionsVisible.value = true;
    isResearchPostsVisible.value = false;
    isCapabilitiesVisible.value = false;
    isThirdPartyDataVisible.value = false;
    tabList.value.forEach((tab) => {
        if(tab.tabName === "Collections"){
            tab.isSelected = true
        } else {
            tab.isSelected = false
        }
    })
}

function showResearchPosts() {
    // console.log('show contact research notes')
    isHistoryVisible.value = false;
    isPrevCampaignsVisible.value = false;
    isContractsVisible.value = false;
    isContactsVisible.value = false;
    isCollectionsVisible.value = false;
    isCapabilitiesVisible.value = false;
    isResearchPostsVisible.value = true;
    isThirdPartyDataVisible.value = false;
    tabList.value.forEach((tab) => {
        if(tab.tabName === "Research"){
            tab.isSelected = true
        } else {
            tab.isSelected = false
        }
    })
}

function showCapabilities() {
    console.log('show contact research notes')
    isHistoryVisible.value = false;
    isPrevCampaignsVisible.value = false;
    isContractsVisible.value = false;
    isContactsVisible.value = false;
    isCollectionsVisible.value = false;
    isResearchPostsVisible.value = false;
    isCapabilitiesVisible.value = true;
    isThirdPartyDataVisible.value = false;
    tabList.value.forEach((tab) => {
        if(tab.tabName === "Capabilities"){
            tab.isSelected = true
        } else {
            tab.isSelected = false
        }
    })
}

function showThirdPartyData() {
    console.log('show contact research notes')
    isHistoryVisible.value = false;
    isPrevCampaignsVisible.value = false;
    isContractsVisible.value = false;
    isContactsVisible.value = false;
    isCollectionsVisible.value = false;
    isResearchPostsVisible.value = false;
    isCapabilitiesVisible.value = false;
    isThirdPartyDataVisible.value = true;
    tabList.value.forEach((tab) => {
        if(tab.tabName === "3rd Party Data"){
            tab.isSelected = true
        } else {
            tab.isSelected = false
        }
    })
}

function displayEditForm(){
    if(!isHistoryVisible.value && !isContractsVisible.value && !isPrevCampaignsVisible.value && !isContactsVisible.value  && !isCollectionsVisible.value && !isResearchPostsVisible.value && !isCapabilitiesVisible.value && !isThirdPartyDataVisible.value){
        return true
    } else {
        return false
    }
}

function displayHistory(){
    return isHistoryVisible.value && !isContractsVisible.value &&!isPrevCampaignsVisible.value && !isContactsVisible.value && !isCollectionsVisible.value && !isResearchPostsVisible.value && !isCapabilitiesVisible.value && !isThirdPartyDataVisible.value
} 

function displayPrevCampaigns(){
    return isPrevCampaignsVisible.value && !isHistoryVisible.value && !isContractsVisible.value && !isContactsVisible.value && !isCollectionsVisible.value && !isResearchPostsVisible.value && !isCapabilitiesVisible.value && !isThirdPartyDataVisible.value
}

function displayContracts(){
    return isContractsVisible.value && !isHistoryVisible.value && !isPrevCampaignsVisible.value && !isContactsVisible.value && !isCollectionsVisible.value && !isResearchPostsVisible.value && !isCapabilitiesVisible.value && !isThirdPartyDataVisible.value
}

function displayContacts(){
    return isContactsVisible.value && !isHistoryVisible.value && !isPrevCampaignsVisible.value && !isContractsVisible.value && !isCollectionsVisible.value && !isResearchPostsVisible.value && !isCapabilitiesVisible.value && !isThirdPartyDataVisible.value
}

function displayCollections(){
    return isCollectionsVisible.value && !isContactsVisible.value && !isHistoryVisible.value && !isPrevCampaignsVisible.value && !isContractsVisible.value && !isResearchPostsVisible.value && !isCapabilitiesVisible.value && !isThirdPartyDataVisible.value
}

function displayResearchPosts(){
    return isResearchPostsVisible.value && !isCollectionsVisible.value && !isContactsVisible.value && !isHistoryVisible.value && !isPrevCampaignsVisible.value && !isContractsVisible.value && !isCapabilitiesVisible.value && !isThirdPartyDataVisible.value
}

function displayCapabilities(){
    return isCapabilitiesVisible.value && !isCollectionsVisible.value && !isContactsVisible.value && !isHistoryVisible.value && !isPrevCampaignsVisible.value && !isContractsVisible.value && !isResearchPostsVisible.value && !isThirdPartyDataVisible.value
}

function displayThirdPartyData() {
    return isThirdPartyDataVisible.value && !isCollectionsVisible.value && !isContactsVisible.value && !isHistoryVisible.value && !isPrevCampaignsVisible.value && !isContractsVisible.value && !isResearchPostsVisible.value && !isCapabilitiesVisible.value
}


function setDefaultTab(){
    if(props.defaultTab === 'edit'){
        showEditCompany()
    } else if (props.defaultTab === 'history'){
        showHistory()
    } else if (props.defaultTab === 'campaigns'){
        showPrevCampaigns()
    } else if (props.defaultTab === 'contracts'){
        showContracts()
    } else if (props.defaultTab === 'contacts'){
        showContacts()
    } else if (props.defaultTab === 'researchPosts') {
        showResearchPosts()
    } else if (props.defaultTab === 'capabilities') {
        showCapabilities()
    } else if (props.defaultTab === 'thirdPartyData') {
        showThirdPartyData()
    } else {
        showEditCompany()
    }
}

setDefaultTab()

sourceWrap();
// loadCampaigns();
loadSourceOwners();
loadPreviousCampaigns();
</script>