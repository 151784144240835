

export function getMostRecentCRMEvent(company) {
    if(company.crm_actions) {
        let crmActionsByDate = company.crm_actions.filter(action => {return action.campaign_id == company.campaign.id}).sort(function(a,b) {
            return new Date(b.resolved_date) - new Date(a.resolved_date)
        })
        if(crmActionsByDate.length > 0) {
            return crmActionsByDate[0]
        }
    } else {
        return null
    }
}

export function getFurthestCRMEvent(company) {
    // console.log('getFurthestCRMEvent')
    if(company.crm_actions) {
        let crmEventIDs = company.crm_actions.filter(action => {return action.campaign_id == company.campaign.id}).map((action) => action.action.id)
        // console.log(crmEventIDs)
        let crmEventsByOrdinal = company.crm_actions.filter(action => {return action.campaign_id == company.campaign.id}).sort(function(a,b) {
            return b.action.ordinal - a.action.ordinal
        })
        // console.log(crmEventsByOrdinal)
        if(crmEventIDs.length > 0) {
            if(crmEventIDs.includes(20)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 20)
            } else if (crmEventIDs.includes(18)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 18)
            } else if (crmEventIDs.includes(17)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 17)
            } else if (crmEventIDs.includes(16)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 16)
            } else if (crmEventIDs.includes(15)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 15)
            } else if (crmEventIDs.includes(14)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 14)
            } else if (crmEventIDs.includes(13)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 13)
            } else if (crmEventIDs.includes(12)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 12)
            } else if (crmEventIDs.includes(11)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 11)
            } else if (crmEventIDs.includes(10)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 10)
            } else if (crmEventIDs.includes(9)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 9)
            } else if (crmEventIDs.includes(22)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 22)
            } else if (crmEventIDs.includes(8)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 8)
            } else if (crmEventIDs.includes(6)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 6)
            } else if (crmEventIDs.includes(7)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 7)
            } else if (crmEventIDs.includes(5)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 5)
            } else if (crmEventIDs.includes(1)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 1)
            } else if (crmEventIDs.includes(21)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 21)
            } else if (crmEventIDs.includes(4)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 4)
            } else if (crmEventIDs.includes(3)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 3)
            } else if (crmEventIDs.includes(2)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 2)
            } else if (crmEventIDs.includes(19)) {
                return crmEventsByOrdinal.find((crmEvent) => crmEvent.action.id == 19)
            } else {
                return null
            }
            // return crmActionsByOrdinal[0]
        }
    } else {
        return null
    }
}

export function getComputedCRMStatus(company) {
    let crmEventIds = [...new Set(company.crm_actions.filter((crmAction) => crmAction.campaign_id == company.campaign.id).filter(crmAction => crmAction.action.resolved_type == 'event').map((crmAction) => crmAction.action_id))]
    // console.log('crmEventIds', crmEventIds)
    if(crmEventIds.length > 0) {
        if(crmEventIds.includes(28) || crmEventIds.includes(29)) {
            return {
                label: 'Dead',
                value: 2
            }
        } else if(crmEventIds.includes(20) || crmEventIds.includes(27)) {
            return {
                label: 'Client / Target Pass',
                value: 2
            }
        } else if (crmEventIds.includes(18)) {
            return {
                label: 'Transaction Closed',
                value: 7
            }
        } else if(crmEventIds.includes(26)) {
            return {
                label: 'Revist / Cut',
                value: 2
            }
        } else if(crmEventIds.includes(25)) {
            return {
                label: 'Not Now / Keep Warm',
                value: 2
            }
        } else if (crmEventIds.includes(16) || crmEventIds.includes(17)) {
            return {
                label: 'Under LOI',
                value: 6
            }
        } else if (crmEventIds.includes(15)) {
            return {
                label: 'IOI Submitted',
                value: 5
            }
        } else if (
            crmEventIds.includes(24) ||
            crmEventIds.includes(14) ||
            crmEventIds.includes(13) ||
            crmEventIds.includes(12) ||
            crmEventIds.includes(11) ||            
            crmEventIds.includes(10) ||
            crmEventIds.includes(9)
        ) {
            return {
                label: 'Active - In Dialogue',
                value: 1
            }
        } else if (
            crmEventIds.includes(22) ||
            crmEventIds.includes(8) ||
            crmEventIds.includes(6)
        ) {
            return {
                label: 'Responded / Coverage',
                value: 5
            }
        } else if (
            crmEventIds.includes(7) ||
            crmEventIds.includes(5) ||
            crmEventIds.includes(4) ||
            crmEventIds.includes(3) ||            
            crmEventIds.includes(2) ||
            crmEventIds.includes(1)
        ) {
            return {
                label: 'No Response',
                value: 3
            }
        } else {
            return {
                label: 'Not Contacted',
                value: 4,
            }
        }
    } else {
        return {
            label: 'Not Contacted',
            value: 4,
        }
    }
}