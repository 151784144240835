import axios from "axios";
import { BASE_URL } from "./api";


export async function getActions(token) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/actions`,
        headers: {
            'Authorization': `Token ${token}`
        }
    }).then(response => response)
    .catch((error) => {
        alert(error)
    })
}

export async function getActionsByResolvedType(token, resolvedType) {
    return axios({
        method: 'get',
        url: `${BASE_URL}/actions/?resolved_type=${resolvedType}`,
        headers: {
            'Authorization': `Token ${token}`
        }
    }).then(response => response)
    .catch((error) => {
        alert(error)
    })
}

export async function createAction(actionData, token) {
    // console.log('form data', actionData)
    return axios({
        method: 'post',
        url: `${BASE_URL}/crm_actions/create_new_event/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: actionData
    }).then((response) => {
        return response
    }).catch((error) => {
        alert(error)
    })
}

export async function updateAction(actionId, formData, token) {
    // console.log('form data', formData)
    return axios({
        method: 'put',
        url: `${BASE_URL}/crm_actions/${actionId}/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: formData
    }).then((response) => {
        return response
    }).catch((error) => {
        alert(error)
    })
}

export async function deleteAction(actionId, token) {
    return axios({
        method: 'delete',
        url: `${BASE_URL}/crm_actions/${actionId}/`,
        headers: {
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        }
    })
    .then(response => response)
    .catch(error => alert(error))
}

export async function markStatus(actionId, status, token){
    return axios({
        method: 'put',
        url: `${BASE_URL}/crm_actions/${actionId}/mark_status/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: status
    }).then((response) => {
        return response
    }).catch((error) => {
        alert(error)
    })
}

export async function markUser(actionId, user, token){
    return axios({
        method: 'put',
        url: `${BASE_URL}/crm_actions/${actionId}/update_action_user/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: user
    }).then((response) => {
        return response
    }).catch((error) => {
        alert(error)
    })
}

export async function markAction(actionId, action, token){
    return axios({
        method: 'put',
        url: `${BASE_URL}/crm_actions/${actionId}/update_action_action/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: action
    }).then((response) => {
        return response
    }).catch((error) => {
        alert(error)
    })
}

export async function markHighlightColor(actionId, color, token){
    return axios({
        method: 'put',
        url: `${BASE_URL}/crm_actions/${actionId}/set_highlight_color/`,
        headers: { 
            'Authorization': `Token ${token}`, 
            'Content-Type': 'application/json'
        },
        data: {
            highlight_color: color
        }
    }).then((response) => {
        return response
    }).catch((error) => {
        alert(error)
    })
}