<template>
    <div class="h-full w-full flex flex-col px-2 sm:px-4 md:px-8 lg:px-12 xl:px-16 2xl:px-28 print:hidden bg-gray-200">
        <div class="flex w-full h-14 border-b border-x justify-start items-center border-gray-300 sticky z-20 top-0 bg-gray-50 pr-4">
            <div @click="hideReport" class="hover:bg-gray-200 rounded-sm h-full px-0.5 md:px-3 flex justify-center items-center cursor-pointer print:hidden">
                <ChevronLeftSVG 
                    fill-color="#25274D"
                />
            </div>
            <!-- <div @click="showPrintPreview" class="hover:bg-gray-200 rounded-sm h-full flex px-0.5 md:px-3 justify-center items-center cursor-pointer print:hidden">
                <PrintSVG
                    fill-color="#25274D"
                />
            </div> -->
            <div class="flex items-center w-fit space-x-3 px-0.5 md:px-3">
                <div  class="text-lg flex items-center font-semibold tracking-widest whitespace-nowrap text-gray-800">
                    {{ clientName }}
                </div>
            </div>
            <div class="flex justify-start items-end h-full w-full overflow-x-scroll">
                <TabBar 
                :tab-list="tabList"
                :first-tab-selected="firstTabSelected"
                :last-tab-selected="lastTabSelected"
                @set-page="setPage"
            />
            </div>
            <div class="flex items-center w-fit text-sm tracking-wide pl-3 whitespace-nowrap pt-0.5 space-x-3 text-gray-600">
                <div @click="showPrintPreview" class="hover:underline underline-offset-4 hover:text-dark-secondary cursor-pointer">
                    Print Preview
                </div>
                <!-- <div class="hover:underline underline-offset-4 hover:text-dark-secondary cursor-pointer">
                    Download CSV
                </div> -->
            </div>
            <div class="flex items-center w-fit justify-end pl-0.5 xl:pl-3  space-x-3 print:hidden">
                <div class="text-base w-fit hidden 2xl:flex items-center  tracking-wider text-gray-600 pt-0.5">
                    Campaigns:
                </div>
                <div class="flex items-center w-[200px] md:w-[300px]  xl:w-[400px] pt-[30px] z-50">
                    <type-ahead-multiselect
                        placeholder="Campaign(s)"
                        nullLabel="No Results"
                        :default-value="selectedCampaigns"
                        :options="campaignOptions"
                        @update-value="updateCampaigns"
                    ></type-ahead-multiselect>
                </div>
            </div>
        </div>
        <div class="w-full h-[calc(100vh-3.5rem)]">
            <CRMTargetList
                v-if="tabList.targetList.isSelected" 
                :selectedCompanies="selectedCompanies"  
                :campaigns="campaigns"
            />
            <CRMOverview 
                v-else-if="tabList.overview.isSelected"
                :clientName="clientName"
                :campaigns="campaigns"
                :selectedCompanies="selectedCompanies"
                :contactedCompanies="contactedCompanies"
                :notContactedCompanies="notContactedCompanies"
                :respondedCompanies="respondedCompanies"
                :noResponseCompanies="noResponseCompanies"
            />
            <!-- <CRMActivities 
                v-else-if="tabList.crmActivities.isSelected"
                :selectedCRMActions="selectedCRMActions"
                :numCRMActions="numCRMActions"
            /> -->
            <!-- <CRMCompanyHistory v-else-if="tabList.companyHistory.isSelected" /> -->

        </div>
    </div>
    <Teleport to="body">
        <div v-show="printPreviewIsVisible" class="modal h-full fixed overflow-y-scroll bg-gray-200 flex justify-center w-[1632px]">
            <div class="flex fixed print:hidden bg-gray-50 shadow-md h-14 border-b justify-center items-center border-gray-300 z-10 w-full">
                <div @click="hidePrintPreview" class="hover:bg-gray-200 rounded-sm h-full px-0.5 md:px-3 flex justify-center items-center cursor-pointer print:hidden">
                    <ChevronLeftSVG 
                        fill-color="#25274D"
                    />
                </div>
                <div @click="handlePrint" class="hover:bg-gray-200 rounded-sm h-full flex px-0.5 md:px-3 justify-center items-center cursor-pointer print:hidden">
                    <PrintSVG
                        fill-color="#25274D"
                    />
                </div>
            </div>
            <div class=" bg-gray-200 relative flex justify-center w-[1632px] top-14">
                <div ref="componentRef" class="bg-white w-full flex flex-col h-full">
                    <div class="flex items-center justify-center h-[2100px] break-after-page p-24 w-full bg-white">
                        <div class="flex flex-col items-center justify-center h-full w-full px-12 bg-dark-secondary">
                            <div class="text-5xl flex items-center justify-center font-extralight tracking-widest whitespace-nowrap text-white py-4 w-1/2  border-b border-gray-50">
                                {{ clientName }}
                            </div>
                            <div class="text-2xl flex justify-center items-center w-full tracking-wider whitespace-nowrap font-medium text-gray-50 py-4">
                                Outreach Overview {{ today }}
                            </div>
                            <div class="w-[300px] h-[360px] absolute bottom-20 flex justify-center">
                                <SheddCapitalSVG 
                                    fill-color="white"
                                />
                            </div>
                        </div>
       
                    </div>
                    <div class="w-full flex flex-col h-[2100px] break-after-page p-24 bg-white">
                        <div class="w-full flex h-14 px-12 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                            <div class='w-full flex space-x-2 justify-start'>
                                <div>All Campaigns</div>
                                <div class="text-base 2xl:flex items-center justify-center tracking-wider whitespace-nowrap text-gray-600 pt-0.5 pb-6">
                                    {{ numCompanies }} {{ companyLabel }} in {{ numCampaigns }} {{ campaignLabel }}
                                </div>
                            </div>
                            <div class="w-[200px] flex justify-end">
                                <SheddCapitalSVG 
                                    fill-color="#1F2937"
                                />
                            </div>
                        </div>
                        <CRMOverview
                            class="h-[1800px]"
                            :printView="true"
                            :clientName="clientName"
                            :campaigns="campaigns"
                            :selectedCompanies="selectedCompanies"
                            :contactedCompanies="contactedCompanies"
                            :notContactedCompanies="notContactedCompanies"
                            :respondedCompanies="respondedCompanies"
                            :noResponseCompanies="noResponseCompanies"
                        />
                    </div>
                    <div 
                        v-for="(companyBatch, index) in selectedCompaniesBatches"
                        :key="index"
                        class="w-full flex flex-col h-[2100px] break-after-page p-24 bg-white">
                        <div class="w-full flex h-14 px-12 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                            <div class='w-full flex space-x-2 justify-start'>
                                <div>All Campaigns</div>
                                <div class="text-base 2xl:flex items-center justify-center tracking-wider whitespace-nowrap text-gray-600 pt-0.5 pb-6">
                                    {{ numCompanies }} {{ companyLabel }} in {{ numCampaigns }} {{ campaignLabel }}
                                </div>
                            </div>
                            <div class="w-[200px] flex justify-end">
                                <SheddCapitalSVG 
                                    fill-color="#1F2937"
                                />
                            </div>
                        </div>
                        <div class="w-full overflow-clip h-[1800px]">
                            <CRMTargetList
                                :print-view="true"
                                :selectedCompanies="companyBatch"  
                                :campaigns="campaigns"
                            />
                        </div>
                    </div>
                    <div
                        v-for="campaign in campaigns"
                        :key="campaign"
                        class="w-full"
                    >
                        <div class="w-full bg-white flex flex-col h-[2100px] break-after-page p-24">
                            <div class="w-full flex h-14 px-12 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                                <div class='w-full flex justify-start'>
                                    Campaign: {{ getCampaignName(campaign) }}
                                </div>
                                <div class="w-[200px] flex justify-end">
                                    <SheddCapitalSVG 
                                        fill-color="#1F2937"
                                    />
                                </div>
                            </div>  
                            <CRMOverview
                                class="h-[1800px]"
                                :printView="true"
                                :clientName="clientName"
                                :campaigns="campaigns"
                                :selectedCompanies="companies.filter((company) => company.campaign.id == campaign)"
                                :contactedCompanies="contactedCompanies.filter((company) => company.campaign.id == campaign)"
                                :notContactedCompanies="notContactedCompanies.filter((company) => company.campaign.id == campaign)"
                                :respondedCompanies="respondedCompanies.filter((company) => company.campaign.id == campaign)"
                                :noResponseCompanies="noResponseCompanies.filter((company) => company.campaign.id == campaign)"
                            />
                        </div>
                        <div class="w-full bg-white flex flex-col h-[2100px] break-after-page p-24">
                            <div class="w-full flex h-14 px-12 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                                <div class='w-full flex justify-start'>
                                    Campaign: {{ getCampaignName(campaign) }}
                                </div>
                                <div class="w-[200px] flex justify-end">
                                    <SheddCapitalSVG 
                                        fill-color="#1F2937"
                                    />
                                </div>
                            </div>
                            <!-- <CRMActivities
                                :selectedCRMActions="selectedCRMActions.filter((crmAction) => crmAction.campaign.id == campaign)"
                                :numCRMActions="numCRMActions"
                            />       -->
                        </div>
                        <div 
                            v-for="(companyBatch, index) in selectedCompaniesBatches"
                            :key="index"
                            class=" bg-white flex flex-col h-[2100px] break-after-page p-24 items-center w-full"
                            >
                            <div class="w-full flex h-14 px-12 items-center justify-between border-b border-gray-300 text-lg font-semibold tracking-widest whitespace-nowrap text-gray-800">
                                <div class='w-full flex justify-start'>
                                    Campaign: {{ getCampaignName(campaign) }}
                                </div>
                                <div class="w-[200px] flex justify-end">
                                    <SheddCapitalSVG 
                                        fill-color="#1F2937"
                                    />
                                </div>
                            </div >
                            <div class="w-full overflow-clip h-[1800px]">
                                <CRMTargetList
                                :print-view="true"
                                :selectedCompanies="companyBatch"  
                                :campaigns="campaigns"
                            />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script setup>
import { ref, reactive, defineProps, defineEmits, computed, watch } from 'vue';
import PrintSVG from '../svg-components/PrintSVG.vue';
import TabBar from '../base-components/TabBar.vue';
import CRMOverview from './CRMOverview.vue';
// import CRMActivities from './CRMActivities.vue';
import CRMCompanyHistory from './CRMCompanyHistory.vue';
import ChevronLeftSVG from '../svg-components/ChevronLeftSVG.vue';
import SheddCapitalSVG from '../svg-components/SheddCapitalSVG.vue';
import CRMTargetList from './CRMTargetList.vue';
import { useVueToPrint } from "vue-to-print";


const props = defineProps(
    ['client', 
    'campaigns',
    'campaignOptions', 
    'companies', 
    'crmActions',
    'clientName', 
    'contactedCompanies', 
    'notContactedCompanies', 
    'respondedCompanies', 
    'noResponseCompanies'
])

const emit = defineEmits(['hide-report'])

const componentRef = ref();

const selectedCampaigns = ref(props.campaigns ? props.campaigns : [])

const selectedCompanies = ref(props.companies ? props.companies : [])

const selectedCRMActions = ref(props.crmActions ? props.crmActions : [])

const selectedCompaniesBatches = computed(() => {
    let companyBatches = []
    for (let  i=0; i< selectedCompanies.value.length; i+=80) {
        companyBatches.push(selectedCompanies.value.slice(i,i+80));
    }
    return companyBatches
})

const printPreviewIsVisible = ref(false)

const today = computed(() => {
    const date = new Date()
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
})

const { handlePrint } = useVueToPrint({
  content: componentRef,
  documentTitle: `${props.clientName} Outreach Overview ${today.value}`,
  removeAfterPrint: true
});

const numCompanies = computed(() => {
    return selectedCompanies.value ? selectedCompanies.value.length : 0
})

const companyLabel = computed(() => {
    if(numCompanies.value == 1) {
        return 'Target'
    }
    return 'Targets'
})

const numSelectedCRMActions = computed(() => {
    return selectedCRMActions.value ? selectedCRMActions.value.length : 0
})

const crmActionLabel = computed(() => {
    if(numCRMActions.value == 1) {
        return 'CRM Event'
    }
    return 'CRM Events'
})

const numCampaigns = computed(() => {
    return props.campaigns ? props.campaigns.length : 0
})

const campaignLabel = computed(() => {
    if(numCampaigns.value == 1) {
        return 'Campaign'
    }
    return 'Campaigns'
})

const numCRMActions = computed(() => {
    return selectedCRMActions.value ? selectedCRMActions.value.length : 0
})

watch(() => selectedCampaigns.value, () => {
    selectedCompanies.value = props.companies.filter((company) => selectedCampaigns.value.includes(company.campaign.id))
    selectedCRMActions.value = props.crmActions.filter((crmAction) => selectedCampaigns.value.includes(crmAction.campaign.id))
}, {deep: true})


const firstTabSelected = ref(true)
const lastTabSelected = ref(false)

const tabList = reactive({
    targetList: {label: 'Target List', isSelected: true, isLeading: false, isFollowing: false},
    overview: {label: 'CRM Overview', isSelected: false, isLeading: false, isFollowing: false}, 
    // crmActivities: {label: 'CRM Activities', isSelected: false, isLeading: false, isFollowing: true},
    // companyHistory: {label: 'Company CRM History', isSelected: false, isLeading: false, isFollowing: false},

})

function setPage(tabKey) {
    clearAllTabs()
    switch (tabKey) {
        case 'overview':
            firstTabSelected.value = true;
            // tabList.crmActivities.isFollowing = true;
            break
        case 'crmActivities':
            tabList.overview.isLeading = true;
            // tabList.companyHistory.isFollowing = true;
            break
        case 'companyHistory':
            // tabList.crmActivities.isLeading = true;
            tabList.targetList.isFollowing = true;
            break
        case 'targetList':
            lastTabSelected.value = true;
            // tabList.companyHistory.isLeading = true;
            break
    }
    tabList[tabKey].isSelected = true;
}

function clearAllTabs() {
    for(const tab of Object.values(tabList)) {
        tab.isSelected = false
        tab.isLeading = false
        tab.isFollowing = false
    }
}

function hideReport() {
    emit('hide-report')
}

function updateCampaigns(campaignIds) {
    selectedCampaigns.value = campaignIds
}

function showPrintPreview() {
    printPreviewIsVisible.value = true
}

function hidePrintPreview() {
    printPreviewIsVisible.value = false
}

function getCampaignName(campaignId) {
    // console.log(props.campaignOptions)
    let thisCampaign = props.campaignOptions.find((campaign) => campaign.value == campaignId)
    // console.log('thisCampaign', thisCampaign)
    return thisCampaign.label
}


</script>

<style scoped>
/* General print styles here  */
.modal {
  position: fixed;
  z-index: 999;
  top: 0%;
  left: 0%;
  width: 100%;
  /* margin-left: -150px; */
}

#printContainer {
  table {
    width: 100%;
    border-collapse: collapse;
    break-inside: auto;
    page-break-inside: auto;
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  table td,
  table th {
    padding: 1.5mm;
    border: 2px solid #ccc;
    border: 2px solid #ccc;
    vertical-align: top;
    font-size: inherit;
  }
  table td.invoice-header {
    border: none;
  }

  table th {
    text-align: left;
    vertical-align: bottom;
    color: rgb(0, 0, 30);
    background-color: #04aa6d;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  table td,
  table th,
  table tr {
    /* Prevent elements from being split across pages in paginated media (like print) */
    break-inside: avoid;
    /* Automatically insert a page break after the element, if needed */
    break-after: auto;
  }
}
/* Apply styles only when the document is being printed */
@media print {
  /* Apply styles to the printed page */
  @page {
    size: auto;
    /* Set the page margins, hide default header and footer */
    margin: 1in 1in 1in 1in !important;
  }

  .page-break {
    .page-break-after: always;
  }

  body {
    /* Ensure that colors are printed exactly as they appear on the screen */
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}
</style>